<template>
  <div class="box">
    <div class="is-flex is-flex-direction-row is-align-items-center is-justify-content-center mb-2">
      <div class="is-size-5 mr-1">{{ $t("admin_panel.inbox_configuration_title") }}</div>
      <b-button type="is-primary" class="is-small" @click="save">{{ $t("admin_panel.save") }}</b-button>
    </div>

    <!--ENABLED-->
    <b-field>
      <b-switch v-model="$data[inboxFields.ENABLED]">{{ $t("admin_panel.inbox_enabled_title") }}</b-switch>
    </b-field>

    <!--COMPONENT ID-->
    <div class="field mt-1">
      <label class="label has-text-primary mb-1">{{ $t("admin_panel.module_component_id_visibility") }}</label>
      <dropdown-select-wrapper
        :items="allComponents"
        v-model="$data[inboxFields.COMPONENT_ID]"
        :item-label-function="item => item.label"
        :item-value-function="item => item.id"
      ></dropdown-select-wrapper>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import LpConfigConstants from "@/shared/constants/lp-config-constants";
import DropdownSelectWrapper from "@/shared/components/form/DropdownSelectWrapper";

export default {
  name: "AdminInboxConfiguration",
  components: { DropdownSelectWrapper },
  data() {
    const inboxFields = LpConfigConstants.INBOX_FIELDS;
    return {
      [inboxFields.ENABLED]: true,
      [inboxFields.COMPONENT_ID]: null,
    };
  },

  computed: {
    ...mapState("adminPanel", ["inbox"]),
    ...mapGetters("components", ["allComponents"]),

    inboxFields: () => LpConfigConstants.INBOX_FIELDS,
  },

  methods: {
    ...mapMutations("adminPanel", ["setInboxConfig"]),

    save() {
      this.setInboxConfig(this.getInboxConfig());
      this.$root.$emit("close-side-panel");
      this.$emit("navigate-back");
    },

    getInboxConfig() {
      const inboxFields = LpConfigConstants.INBOX_FIELDS;
      return {
        [inboxFields.ENABLED]: this.$data[inboxFields.ENABLED],
        [inboxFields.COMPONENT_ID]: this.$data[inboxFields.COMPONENT_ID],
      };
    },
  },

  watch: {
    inbox: {
      immediate: true,
      handler: function (newValue) {
        const inboxFields = LpConfigConstants.INBOX_FIELDS;

        this.$data[inboxFields.ENABLED] = newValue[inboxFields.ENABLED] || true;
        this.$data[inboxFields.COMPONENT_ID] = newValue[inboxFields.COMPONENT_ID] || null;
      },
    },
  },
};
</script>

<style scoped></style>
