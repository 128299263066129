<template>
  <div class="fw-comment-container is-relative" :class="isSmallClass">
    <div class="columns is-mobile is-gapless mb-0">
      <div class="column is-narrow">
        <profile-picture
          :initials="userInitials"
          :picture="userAvatar"
          class="fw-comment-avatar"
          :class="isSmallClass"
          @click.stop.native="openUserProfile"
        ></profile-picture>
      </div>
      <div class="column">
        <div class="is-flex is-flex-direction-row is-align-items-center">
          <div class="fw-comment-title is-flex-grow-1" :class="isSmallClass">
            {{ userName }}
          </div>
          <div class="fw-comment-date" :class="isSmallClass">
            {{ date }}
          </div>
        </div>
        <div class="fw-comment-message" :class="`${isSmallClass} ${isListItemClass}`">
          {{ message }}
        </div>
      </div>
    </div>
    <div v-if="!isListItem && (isFromCurrentUser || isAdmin)" class="fw-comment-actions-overlay">
      <edit-icon class="fw-comment-actions-icon" @click="startEditComment" v-if="isFromCurrentUser"></edit-icon>
      <trash-bin-icon class="fw-comment-actions-icon" @click="tryToDeleteComment"></trash-bin-icon>
    </div>
  </div>
</template>

<script>
import { initials, fullName, composeElapsedTime } from "@/shared/utils";
import ProfilePicture from "@/shared/components/common/profile/ProfilePicture";
import UserProfileModal from "@/web/components/profile/UserProfileModal";
import TrashBinIcon from "@/assets/icons/trash-bin.svg";
import EditIcon from "@/assets/icons/edit_2.svg";
import { mapGetters, mapActions } from "vuex";
import FeedWallCreateThreadModal from "@/web/components/feedwall/FeedWallCreateThreadModal";
import FeedWallDeleteConfirmationModal from "@/web/components/feedwall/FeedWallDeleteConfirmationModal";

export default {
  name: "FeedWallComment",
  components: { ProfilePicture, EditIcon, TrashBinIcon },
  props: {
    comment: {
      type: Object,
      required: true,
    },

    channelId: {
      type: Number,
      required: true,
    },

    isSmall: {
      type: Boolean,
      default: false,
    },

    isListItem: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters("currentUser", {
      currentUserUuid: "uuid",
    }),
    ...mapGetters("auth", ["isAdmin"]),
    ...mapGetters(["currentTime"]),

    message() {
      return this.comment.message;
    },

    userAvatar() {
      return this.comment.user.picture;
    },

    userInitials() {
      return initials(this.comment.user);
    },

    userName() {
      return fullName(this.comment.user);
    },

    userUuid() {
      return this.comment.user.user_identity_token;
    },

    isFromCurrentUser() {
      return this.userUuid === this.currentUserUuid;
    },

    date() {
      if (this.currentTime) {
        return composeElapsedTime(this.comment.created_at, this.$i18n);
      } else {
        return "";
      }
    },

    isSmallClass() {
      if (this.isSmall) {
        return "is-small";
      } else {
        return "";
      }
    },

    isListItemClass() {
      if (this.isListItem) {
        return "is-list-item";
      } else {
        return "";
      }
    },
  },

  methods: {
    ...mapActions("feedWallThread", ["deleteComment"]),

    startEditComment() {
      this.$buefy.modal.open({
        parent: this,
        component: FeedWallCreateThreadModal,
        hasModalCard: true,
        canCancel: true,
        trapFocus: true,
        props: { channelId: this.channelId, comment: this.comment },
      });
    },

    tryToDeleteComment() {
      this.$buefy.modal.open({
        parent: this,
        component: FeedWallDeleteConfirmationModal,
        hasModalCard: true,
        canCancel: true,
        trapFocus: true,
        props: { channelId: this.channelId, comment: this.comment },
      });
    },

    openUserProfile() {
      this.$buefy.modal.open({
        parent: this,
        component: UserProfileModal,
        hasModalCard: true,
        canCancel: true,
        trapFocus: true,
        props: {
          userId: this.userUuid,
        },
      });
    },
  },
};
</script>

<style scoped></style>
