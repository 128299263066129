<template>
  <div class="box">
    <b-button type="is-primary" class="has-fullwidth mb-1" @click="addValue">
      {{ $t("admin_panel.faq_add_question") }}
    </b-button>

    <template v-for="(tab, index) in values">
      <div class="field box has-image is-relative" :key="`tab-${index}`">
        <!--QUESTION-->
        <div class="field">
          <label class="label has-text-primary">{{ $t("admin_panel.faq_question_title") }}</label>
          <b-button type="is-primary" class="admin-translation-key-button" @click="editQuestion(index)">
            {{ tab[FAQ_QUESTION_FIELDS.QUESTION] || $t("admin_panel.select_translation_key") }}
          </b-button>
        </div>

        <!--ANSWER-->
        <div class="field">
          <label class="label has-text-primary">{{ $t("admin_panel.faq_answer_title") }}</label>
          <b-button type="is-primary" class="admin-translation-key-button" @click="editAnswer(index)">
            {{ tab[FAQ_QUESTION_FIELDS.ANSWER] || $t("admin_panel.select_translation_key") }}
          </b-button>
        </div>

        <div class="close-icon" @click="removeValue">
          <icon-close class="svg-icon"></icon-close>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import LpConfigConstants from "@/shared/constants/lp-config-constants";
import IconClose from "@/assets/icon_close.svg";

export default {
  name: "FaqQuestionsEditor",
  components: { IconClose },

  props: {
    value: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      values: this.value,
      indexAwaitingForTranslation: null,
      awaitingFieldForTranslationKey: null,
    };
  },

  created() {
    this.$root.$on("translation-key-selected", this.onTranslationKeySelected);
  },

  beforeDestroy() {
    this.$root.$off("translation-key-selected", this.onTranslationKeySelected);
  },

  computed: {
    FAQ_QUESTION_FIELDS: () => LpConfigConstants.FAQ_QUESTION_FIELDS,
  },

  methods: {
    addValue() {
      this.values.push({
        [this.FAQ_QUESTION_FIELDS.QUESTION]: null,
        [this.FAQ_QUESTION_FIELDS.ANSWER]: null,
      });
    },

    removeValue(index) {
      this.values.splice(index, 1);
    },

    editQuestion(index) {
      this.indexAwaitingForTranslation = index;
      this.awaitingFieldForTranslationKey = this.FAQ_QUESTION_FIELDS.QUESTION;
      this.$root.$emit("select-translation-key");
    },

    editAnswer(index) {
      this.indexAwaitingForTranslation = index;
      this.awaitingFieldForTranslationKey = this.FAQ_QUESTION_FIELDS.ANSWER;
      this.$root.$emit("select-translation-key");
    },

    onTranslationKeySelected(translationKey) {
      if (this.indexAwaitingForTranslation !== null && this.awaitingFieldForTranslationKey !== null) {
        this.values[this.indexAwaitingForTranslation][this.awaitingFieldForTranslationKey] = translationKey;
      }
      this.indexAwaitingForTranslation = null;
      this.awaitingFieldForTranslationKey = null;
    },
  },
};
</script>

<style scoped lang="scss">
.close-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 24px;
  height: 24px;
  cursor: pointer;
  border-radius: 2px;
  padding: 2px;

  &:hover {
    background: lightgrey;
  }
}
</style>
