<template>
  <div>
    <!--MODULE SPECIFIC FIELDS-->
    <div class="mt-1">
      <!--PAGE SIZE-->
      <div class="field">
        <label class="label has-text-primary">{{ $t("admin_panel.attendees_agenda_session_source") }}</label>
        <div class="control">
          <input
            class="input is-simple"
            type="number"
            min="0"
            step="1"
            :placeholder="$t('admin_panel.attendees_agenda_session_id')"
            v-model.number="$data[moduleFields.AGENDA_SESSION_ID]"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LpConfigConstants from "@/shared/constants/lp-config-constants";

export default {
  name: "AdminModuleConfigAttendees",

  props: {
    value: {
      type: Object,
      required: true,
    },
  },

  data() {
    const moduleFields = LpConfigConstants.ATTENDEES_MODULE_FIELDS;
    return {
      [moduleFields.AGENDA_SESSION_ID]: this.value[moduleFields.AGENDA_SESSION_ID],
    };
  },

  computed: {
    moduleFields: () => LpConfigConstants.ATTENDEES_MODULE_FIELDS,

    specificFieldsObject() {
      return Object.values(this.moduleFields).reduce((acc, fieldKey) => {
        return { ...acc, [fieldKey]: this.$data[fieldKey] };
      }, {});
    },
  },

  watch: {
    specificFieldsObject: {
      immediate: false,
      handler(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
};
</script>

<style scoped></style>
