<template>
  <dropdown-select :items="navbarActionTypes" :value="currentIndex" @input="onNewSelection">
    <template #dropdown="{ item }">
      <span>{{ item }}</span>
    </template>

    <template #placeholder>
      <span class="has-text-secondary">{{ $t("common.select_from_list") }}</span>
    </template>
  </dropdown-select>
</template>

<script>
import DropdownSelect from "@/shared/components/DropdownSelect";
import LpConfigConstants from "@/shared/constants/lp-config-constants";

export default {
  name: "NavbarActionPicker",
  components: { DropdownSelect },

  props: {
    value: {
      type: String,
      required: false,
    },
  },

  data() {
    const navbarActionTypes = LpConfigConstants.NAVBAR_ACTION_TYPES;
    return {
      currentIndex: Object.values(navbarActionTypes).indexOf(this.value),
    };
  },

  computed: {
    navbarActionTypes: () => Object.values(LpConfigConstants.NAVBAR_ACTION_TYPES),
  },

  methods: {
    onNewSelection(index) {
      this.currentIndex = index;
      this.$emit("input", this.navbarActionTypes[index]);
    },
  },
};
</script>

<style scoped>

</style>
