<template>
  <div class="is-relative">
    <template v-if="threads.length">
      <feed-wall-thread
        class="mb-1"
        v-for="thread in threads"
        :key="thread.id"
        :thread="thread"
        :is-small="isSmall"
        :is-list-item="true"
        :sticky-title="channel.name"
        @open-thread="$emit('open-thread', $event)"
      ></feed-wall-thread>
      <list-loading-indicator :active="isLoading"></list-loading-indicator>
    </template>
    <b-loading v-else-if="isLoading" :is-full-page="false" :active="true" style="height: 320px"></b-loading>
    <div v-else class="placeholder-container has-text-secondary">
      {{ $t("feedwall.feedwall_empty_threads") }}
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import FeedWallThread from "@/web/components/feedwall/FeedWallThread";
import ListLoadingIndicator from "@/shared/components/common/loading/ListLoadingIndicator";

export default {
  name: "FeedWallThreads",

  components: {ListLoadingIndicator, FeedWallThread},

  props: {
    channelId: {
      type: Number,
      required: true,
    },

    isSmall: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {};
  },

  computed: {
    ...mapGetters("feedWallChannel", ["threadsFromChannel"]),
    ...mapGetters("feedWall", ["getChannelById"]),

    threads() {
      return this.threadsFromChannel(this.channelId);
    },

    channel() {
      return this.getChannelById(this.channelId);
    }
  },
};
</script>

<style scoped>
.placeholder-container {
  width: 100%;
  height: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-size: 16px;
}
</style>
