export function setGTagScripts(eventId) {
  if (!eventId) {
    return;
  }

  // green energy tricity
  if (eventId === 1488) {
    const headScript = document.createElement('script');
    headScript.innerHTML = `
      <!-- Google Tag Manager -->
      (function(w,d,s,l,i){
        w[l]=w[l]||[];
        w[l].push({
          'gtm.start': new Date().getTime(),
          event:'gtm.js'
        });
        var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),
          dl=l!='dataLayer'?'&l='+l:'';
        j.async=true;
        j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
        f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-N9HSBMKL');
    `;
    document.head.appendChild(headScript);

    // Google Tag Manager - body noscript
    const bodyNoScript = document.createElement('noscript');
    bodyNoScript.innerHTML = `
      <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-N9HSBMKL"
        height="0" width="0" style="display:none;visibility:hidden"></iframe>
    `;
    document.body.appendChild(bodyNoScript);
  } else if (eventId === 1441) {
    const headScript = document.createElement('script');
    headScript.innerHTML = `<!-- Google Tag Manager -->
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-K3RC3SR2');
    <!-- End Google Tag Manager -->`;
    document.head.appendChild(headScript);

    // Google Tag Manager - body noscript
    const bodyNoScript = document.createElement('noscript');
    bodyNoScript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-K3RC3SR2"
    height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
    document.body.appendChild(bodyNoScript);
  } else if (eventId === 1538) {
    const headScript = document.createElement('script');
    headScript.innerHTML = `<!-- Google Tag Manager -->
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-PCZV9DGB');
    <!-- End Google Tag Manager -->`;
    document.head.appendChild(headScript);

    // Google Tag Manager - body noscript
    const bodyNoScript = document.createElement('noscript');
    bodyNoScript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PCZV9DGB"
    height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
    document.body.appendChild(bodyNoScript);
  } else if (eventId === 1468) {
    const headScript = document.createElement('script');
    headScript.innerHTML = `<!-- Google Tag Manager -->
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-TLB8GD62');
    <!-- End Google Tag Manager -->`;
    document.head.appendChild(headScript);

    // Google Tag Manager - body noscript
    const bodyNoScript = document.createElement('noscript');
    bodyNoScript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TLB8GD62"
    height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
    document.body.appendChild(bodyNoScript);
  }
}