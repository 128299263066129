<template>
  <div class="box">
    <!--MODULES LIST-->
    <div v-if="currentSubpanel === SUBPANELS.MODULES_ORDER">
      <b-button type="is-primary" class="is-fullwidth mb-1" @click="generateModules">
        {{ $t("admin_panel.modules_fill_with_event_components") }}
      </b-button>
      <b-button type="is-primary" class="has-fullwidth is-small mb-2" @click="addModule">
        {{ $t("admin_panel.add_new") }}
      </b-button>
      <div v-for="module in modules" :key="`module-${module.id}`">
        <div class="columns box is-variable is-vcentered is-1 is-mobile" style="padding: 2px">
          <div class="column is-narrow">
            <div class="is-flex is-flex-direction-column is-justify-content-flex-end">
              <div
                class="button is-primary module-order-small-icon-button"
                @click="moveModuleUp(module)"
                v-if="module[LpConfigConstants.COMMON_MODULE_FIELDS.ORDER] > 0"
              >
                <arrow-up class="svg-icon" style="fill: white"></arrow-up>
              </div>
              <div
                class="button is-primary module-order-small-icon-button mt-1"
                @click="moveModuleDown(module)"
                v-if="module[LpConfigConstants.COMMON_MODULE_FIELDS.ORDER] < modules.length - 1"
              >
                <arrow-down class="svg-icon" style="fill: white"></arrow-down>
              </div>
            </div>
          </div>
          <div class="column is-size-7">
            <div>
              <div class="module-order-small-text">
                <span class="has-text-weight-bold">{{ `${$t("admin_panel.module_title")}:` }}</span>
                <span>{{ module[LpConfigConstants.COMMON_MODULE_FIELDS.TITLE] }}</span>
              </div>
              <div class="module-order-small-text">
                <span class="has-text-weight-bold">{{ `${$t("admin_panel.module_type")}:` }}</span>
                <span>{{ module[LpConfigConstants.COMMON_MODULE_FIELDS.TYPE] }}</span>
              </div>
              <div class="module-order-small-text">
                <span class="has-text-weight-bold">{{ `${$t("admin_panel.module_order")}:` }}</span>
                <span>{{ module[LpConfigConstants.COMMON_MODULE_FIELDS.ORDER] }}</span>
              </div>
            </div>
          </div>
          <div class="column is-narrow">
            <div class="is-flex is-flex-direction-column">
              <div class="button is-warning module-order-small-icon-button" @click="editModule(module)">
                <edit-icon class="svg-icon"></edit-icon>
              </div>
              <div
                class="button is-danger module-order-small-icon-button mt-1"
                style="padding: 2px !important"
                @click="removeModule(module)"
              >
                <trash-bin-icon class="svg-icon"></trash-bin-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
      <b-button type="is-primary" class="has-fullwidth is-small mt-2" @click="addModule">
        {{ $t("admin_panel.add_new") }}
      </b-button>
    </div>
    <template v-else-if="currentSubpanel === SUBPANELS.EDIT_MODULE">
      <admin-module-config-main :current-module="moduleToEdit" @navigate-back="goBack"></admin-module-config-main>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions, mapState } from "vuex";
import { getDefaultModule, LpConfigConstants } from "@/shared/constants/lp-config-constants";
import ArrowDown from "@/assets/arrow-down.svg";
import ArrowUp from "@/assets/arrow-up.svg";
import TrashBinIcon from "@/assets/icons/trash-bin.svg";
import EditIcon from "@/assets/icons/edit_2.svg";
import AdminModuleConfigMain from "@/web/components/admin/subpanels/modules/AdminModuleConfigMain";

const SUBPANELS = {
  MODULES_ORDER: "modules_order",
  EDIT_MODULE: "edit_module",
};

export default {
  name: "AdminModulesConfiguration",

  components: {
    AdminModuleConfigMain,
    ArrowDown,
    ArrowUp,
    TrashBinIcon,
    EditIcon,
  },

  data() {
    return {
      currentSubpanel: SUBPANELS.MODULES_ORDER,
      moduleToEdit: null,
    };
  },

  computed: {
    ...mapState("adminPanel", ["modules"]),

    LpConfigConstants: () => LpConfigConstants,

    SUBPANELS: () => SUBPANELS,
  },

  methods: {
    ...mapMutations("adminPanel", ["setModulesConfig", "changeModuleOrder", "removeModule"]),
    ...mapActions("adminPanel", ["generateModulesFromComponents"]),
    ...mapGetters("components", ["allComponents"]),

    save() {
      this.setModulesConfig(this.modules);
      this.$root.$emit("close-side-panel");
      this.$emit("navigate-back");
    },

    moveModuleUp(module) {
      const currentOrder = module[LpConfigConstants.COMMON_MODULE_FIELDS.ORDER];
      this.changeModuleOrder({ currentOrder: currentOrder, newOrder: currentOrder - 1 });
    },

    moveModuleDown(module) {
      const currentOrder = module[LpConfigConstants.COMMON_MODULE_FIELDS.ORDER];
      this.changeModuleOrder({ currentOrder: currentOrder, newOrder: currentOrder + 1 });
    },

    editModule(module) {
      this.moduleToEdit = module;
      this.currentSubpanel = SUBPANELS.EDIT_MODULE;
    },

    addModule() {
      const moduleFields = LpConfigConstants.COMMON_MODULE_FIELDS;
      const freshModule = {
        ...getDefaultModule(),
        [moduleFields.ORDER]: this.modules.length,
      };
      this.editModule(freshModule);
    },

    generateModules() {
      this.generateModulesFromComponents(this.allComponents());
    },

    goBack() {
      if (this.currentSubpanel === SUBPANELS.EDIT_MODULE) {
        this.$root.$emit("close-side-panel");
        this.currentSubpanel = SUBPANELS.MODULES_ORDER;
        this.moduleToEdit = null;
      }
    },
  },
};
</script>

<style scoped></style>
