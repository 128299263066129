<template>
  <div class="box">
    <div class="is-size-5 has-text-centered">{{ $t("admin_panel.navbar_configuration_title") }}</div>
    <div class="is-flex is-flex-direction-row is-align-items-center is-justify-content-center mb-2">
      <b-button type="is-primary" class="is-small mr-1" @click="save">{{ $t("admin_panel.save") }}</b-button>
      <b-button type="is-primary" class="is-small mr-1" @click="goBack" v-if="currentSubpanel">
        {{ $t("admin_panel.return") }}
      </b-button>
    </div>

    <template v-if="!currentSubpanel">
      <!--LOGO WIDTH-->
      <div class="field">
        <label class="label has-text-primary">{{ $t("admin_panel.navbar_logo_width") }}</label>
        <div class="control">
          <input
            class="input is-simple"
            type="text"
            :placeholder="$t('admin_panel.navbar_logo_width')"
            v-model="logoWidth"
          />
        </div>
      </div>


      <!--LOGO BACKGROUND COLOR-->
      <div class="field">
        <label class="label has-text-primary">{{ $t("admin_panel.navbar_logo_background_color") }}</label>
        <div class="control">
          <input class="input is-simple" type="color" :placeholder="$t('admin_panel.navbar_logo_background_color')"
                 v-model="logoBackgroundColor"/>
        </div>
      </div>

      <!--LOGO WITH OUTLINE-->
      <b-field>
        <b-switch v-model="logoWithOutline">{{ $t("admin_panel.navbar_logo_with_outline") }}</b-switch>
      </b-field>

      <!--BACKGROUND COLOR-->
      <div class="field">
        <label class="label has-text-primary">{{ $t("admin_panel.navbar_background_color") }}</label>
        <div class="control">
          <input class="input is-simple" type="color" :placeholder="$t('admin_panel.navbar_background_color')"
                 v-model="backgroundColor"/>
        </div>
      </div>

      <!--BACKGROUND IMAGE URL-->
      <div class="field">
        <label class="label has-text-primary">{{ $t("admin_panel.navbar_background_image_url") }}</label>
        <b-button type="is-primary" class="admin-translation-key-button" @click="editBackgroundImageUrl">
          {{ backgroundImageUrlTranslationKey }}
        </b-button>
      </div>

      <!--BACKGROUND IMAGE SIZE-->
      <div class="field">
        <label class="label has-text-primary">{{ $t("admin_panel.navbar_background_image_size") }}</label>
        <div class="control">
          <input
            class="input is-simple"
            type="text"
            :placeholder="$t('admin_panel.navbar_background_image_size')"
            v-model="backgroundImageSize"
          />
        </div>
      </div>

      <!--BACKGROUND IMAGE REPEAT-->
      <div class="field">
        <label class="label has-text-primary">{{ $t("admin_panel.navbar_background_image_repeat") }}</label>
        <div class="control">
          <input
            class="input is-simple"
            type="text"
            :placeholder="$t('admin_panel.navbar_background_image_repeat')"
            v-model="backgroundImageRepeat"
          />
        </div>
      </div>

      <!--BUTTONS TEXT COLOR-->
      <div class="field">
        <label class="label has-text-primary">{{ $t("admin_panel.navbar_buttons_text_color") }}</label>
        <div class="control">
          <input class="input is-simple" type="color" :placeholder="$t('admin_panel.navbar_buttons_text_color')"
                 v-model="buttonTextColor"/>
        </div>
      </div>

      <!--ICON COLOR-->
      <div class="field">
        <label class="label has-text-primary">{{ $t("admin_panel.navbar_icon_fill_color") }}</label>
        <div class="control">
          <input class="input is-simple" type="color" :placeholder="$t('admin_panel.navbar_icon_fill_color')"
                 v-model="iconColor"/>
        </div>
      </div>

      <!--CUSTOM BUTTONS-->
      <b-button type="is-primary" class="is-fullwidth" @click="editCustomButtons">
        {{ $t("admin_panel.navbar_custom_buttons") }}
      </b-button>
    </template>

    <template v-else-if="currentSubpanel === SUBPANELS.CUSTOM_BUTTONS">
      <div>
        <b-button type="is-primary" class="is-small is-fullwidth" @click="addCustomButton">{{
            $t("admin_panel.add_new")
          }}
        </b-button>
        <div
          class="box has-logo mt-1"
          v-for="customButton in customButtons"
          :key="`customButton-${customButton[CUSTOM_BUTTON_FIELDS.ORDER]}`"
        >
          <div class="is-flex is-flex-direction-row">
            <div class="is-flex is-flex-direction-column is-justify-content-center pl-1">
              <div class="edit-button" @click="editCustomButton(customButton[CUSTOM_BUTTON_FIELDS.ORDER])">
                <edit-icon class="svg-icon has-fill-primary"></edit-icon>
              </div>

              <div class="edit-button" style="margin-top: 4px"
                   @click="removeCustomButton(customButton[CUSTOM_BUTTON_FIELDS.ORDER])">
                <trash-bin-icon class="svg-icon"></trash-bin-icon>
              </div>
            </div>
            <div class="pl-1">
              <div class="is-size-8">
                {{
                  `${$t("admin_panel.navbar_custom_button_title")}: ${customButton[CUSTOM_BUTTON_FIELDS.NAVBAR_TITLE]}`
                }}
              </div>
              <div class="is-size-8">
                {{ `${$t("admin_panel.navbar_custom_button_type")}: ${customButton[CUSTOM_BUTTON_FIELDS.TYPE]}` }}
              </div>
              <div class="is-size-8">
                {{ `${$t("admin_panel.navbar_custom_button_order")}: ${customButton[CUSTOM_BUTTON_FIELDS.ORDER]}` }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template v-else-if="currentSubpanel === SUBPANELS.CUSTOM_BUTTON_EDIT_MODE">
      <!-- NAVBAR TITLE -->
      <div class="field">
        <label class="label has-text-primary">{{ $t("admin_panel.navbar_custom_button_title") }}</label>
        <b-button
          type="is-primary"
          class="admin-translation-key-button"
          @click="editCustomButtonTranslationKey(CUSTOM_BUTTON_FIELDS.NAVBAR_TITLE)"
        >
          {{ customButtonToEdit[CUSTOM_BUTTON_FIELDS.NAVBAR_TITLE] || $t("admin_panel.select_translation_key") }}
        </b-button>
      </div>

      <!-- TYPE -->
      <div class="field">
        <label class="label has-text-primary mb-1">{{ $t("admin_panel.navbar_custom_button_type") }}</label>
        <dropdown-select
          :items="customButtonTypes"
          :value="customButtonTypes.indexOf(customButtonToEdit[CUSTOM_BUTTON_FIELDS.TYPE])"
          @input="onCustomButtonTypeSelected"
        >
          <template #dropdown="{ item }">
            <span>{{ item }}</span>
          </template>

          <template #placeholder>
            <span class="has-text-secondary">{{ $t("common.select_from_list") }}</span>
          </template>
        </dropdown-select>
      </div>

      <!-- TARGET -->
      <div class="field" v-if="customButtonToEdit[CUSTOM_BUTTON_FIELDS.TYPE] === CUSTOM_BUTTON_FIELDS.TYPES.REDIRECT">
        <label class="label has-text-primary mb-1">{{ $t("admin_panel.navbar_custom_button_target_type") }}</label>
        <dropdown-select
          :items="customButtonTargets"
          :value="customButtonTargets.indexOf(customButtonToEdit[CUSTOM_BUTTON_FIELDS.TARGET])"
          @input="onCustomButtonTargetSelected"
        >
          <template #dropdown="{ item }">
            <span>{{ item }}</span>
          </template>

          <template #placeholder>
            <span class="has-text-secondary">{{ $t("common.select_from_list") }}</span>
          </template>
        </dropdown-select>
      </div>

      <!-- REDIRECT URL -->
      <div class="field" v-if="customButtonToEdit[CUSTOM_BUTTON_FIELDS.TYPE] === CUSTOM_BUTTON_FIELDS.TYPES.REDIRECT">
        <label class="label has-text-primary">{{ $t("admin_panel.navbar_custom_button_redirect_url") }}</label>
        <b-button type="is-primary" class="is-fullwidth"
                  @click="editCustomButtonTranslationKey(CUSTOM_BUTTON_FIELDS.URL)">
          {{ customButtonToEdit[CUSTOM_BUTTON_FIELDS.URL] || $t("admin_panel.select_translation_key") }}
        </b-button>
      </div>

      <!-- MODAL TITLE -->
      <div class="field" v-if="customButtonToEdit[CUSTOM_BUTTON_FIELDS.TYPE] === CUSTOM_BUTTON_FIELDS.TYPES.MODAL">
        <label class="label has-text-primary">{{ $t("admin_panel.navbar_custom_button_modal_title") }}</label>
        <b-button type="is-primary" class="is-fullwidth"
                  @click="editCustomButtonTranslationKey(CUSTOM_BUTTON_FIELDS.MODAL_TITLE)">
          {{ customButtonToEdit[CUSTOM_BUTTON_FIELDS.MODAL_TITLE] || $t("admin_panel.select_translation_key") }}
        </b-button>
      </div>

      <!-- MODAL DESCRIPTION -->
      <div class="field" v-if="customButtonToEdit[CUSTOM_BUTTON_FIELDS.TYPE] === CUSTOM_BUTTON_FIELDS.TYPES.MODAL">
        <label class="label has-text-primary">{{ $t("admin_panel.navbar_custom_button_modal_description") }}</label>
        <b-button type="is-primary" class="is-fullwidth"
                  @click="editCustomButtonTranslationKey(CUSTOM_BUTTON_FIELDS.MODAL_DESCRIPTION)">
          {{ customButtonToEdit[CUSTOM_BUTTON_FIELDS.MODAL_DESCRIPTION] || $t("admin_panel.select_translation_key") }}
        </b-button>
      </div>

      <!-- MODAL IMAGE URL -->
      <div class="field" v-if="customButtonToEdit[CUSTOM_BUTTON_FIELDS.TYPE] === CUSTOM_BUTTON_FIELDS.TYPES.MODAL">
        <label class="label has-text-primary">{{ $t("admin_panel.navbar_custom_button_modal_image_url") }}</label>
        <b-button type="is-primary" class="is-fullwidth"
                  @click="editCustomButtonTranslationKey(CUSTOM_BUTTON_FIELDS.MODAL_IMAGE_URL)">
          {{ customButtonToEdit[CUSTOM_BUTTON_FIELDS.MODAL_IMAGE_URL] || $t("admin_panel.select_translation_key") }}
        </b-button>
      </div>
    </template>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import LpConfigConstants from "@/shared/constants/lp-config-constants";
import EditIcon from "@/assets/icons/edit.svg";
import TrashBinIcon from "@/assets/icons/trash-bin.svg";
import DropdownSelect from "@/shared/components/DropdownSelect";

const SUBPANELS = {
  CUSTOM_BUTTONS: "custom_buttons",
  CUSTOM_BUTTON_EDIT_MODE: "custom_button_edit_mode",
};

export default {
  name: "AdminNavbarConfiguration",

  components: { EditIcon, TrashBinIcon, DropdownSelect },

  data() {
    return {
      currentSubpanel: null,
      customButtonToEdit: null,
      customButtonFieldAwaitingTranslationKey: null,
      awaitingFieldForTranslationKey: null,
      //Config fields
      logoWidth: null,
      logoBackgroundColor: null,
      logoWithOutline: false,
      backgroundColor: null,
      backgroundImageUrl: null,
      backgroundImageSize: null,
      backgroundImageRepeat: null,
      buttonTextColor: null,
      iconColor: null,
      customButtons: [],
    };
  },

  created() {
    this.$root.$on("translation-key-selected", this.onTranslationKeySelected);
  },

  beforeDestroy() {
    this.$root.$off("translation-key-selected", this.onTranslationKeySelected);
  },

  computed: {
    ...mapState("adminPanel", ["navbar"]),

    SUBPANELS: () => SUBPANELS,

    CUSTOM_BUTTON_FIELDS: () => LpConfigConstants.NAVBAR_FIELDS.CUSTOM_BUTTONS_FIELDS,

    customButtonTypes() {
      return Object.values(LpConfigConstants.NAVBAR_FIELDS.CUSTOM_BUTTONS_FIELDS.TYPES);
    },

    customButtonTargets() {
      return Object.values(LpConfigConstants.NAVBAR_FIELDS.CUSTOM_BUTTONS_FIELDS.TARGETS);
    },

    backgroundImageUrlTranslationKey() {
      return this.backgroundImageUrl || "Select translation key";
    },
  },

  methods: {
    ...mapMutations("adminPanel", ["setNavbarConfig"]),

    save() {
      if (this.currentSubpanel === SUBPANELS.CUSTOM_BUTTON_EDIT_MODE) {
        this.updateCustomButton(this.customButtonToEdit);
        this.goBack();
      } else {
        this.setNavbarConfig(this.getNavbarConfig());
        this.$emit("navigate-back");
        this.$root.$emit("close-side-panel");
      }
    },

    getNavbarConfig() {
      const navbarFields = LpConfigConstants.NAVBAR_FIELDS;
      return {
        [navbarFields.LOGO_WIDTH]: this.logoWidth,
        [navbarFields.LOGO_BACKGROUND_COLOR]: this.logoBackgroundColor,
        [navbarFields.LOGO_WITH_OUTLINE]: this.logoWithOutline,
        [navbarFields.BACKGROUND_COLOR]: this.backgroundColor,
        [navbarFields.BACKGROUND_IMAGE_URL]: this.backgroundImageUrl,
        [navbarFields.BACKGROUND_IMAGE_SIZE]: this.backgroundImageSize,
        [navbarFields.BACKGROUND_IMAGE_REPEAT]: this.backgroundImageRepeat,
        [navbarFields.BUTTON_TEXT_COLOR]: this.buttonTextColor,
        [navbarFields.ICON_COLOR]: this.iconColor,
        [navbarFields.CUSTOM_BUTTONS]: this.customButtons,
      };
    },

    editCustomButtons() {
      this.currentSubpanel = SUBPANELS.CUSTOM_BUTTONS;
    },

    goBack() {
      if (this.currentSubpanel === SUBPANELS.CUSTOM_BUTTON_EDIT_MODE) {
        this.customButtonToEdit = null;
        this.currentSubpanel = SUBPANELS.CUSTOM_BUTTONS;
      } else {
        this.currentSubpanel = null;
      }
      this.$root.$emit("close-side-panel");
    },

    //BACKGROUND IMAGE URL

    editBackgroundImageUrl() {
      this.awaitingFieldForTranslationKey = LpConfigConstants.NAVBAR_FIELDS.BACKGROUND_IMAGE_URL;
      this.$root.$emit("select-translation-key");
    },

    //CUSTOM BUTTONS

    addCustomButton() {
      this.customButtonToEdit = {
        [this.CUSTOM_BUTTON_FIELDS.ORDER]: this.customButtons.length,
      };
      this.currentSubpanel = SUBPANELS.CUSTOM_BUTTON_EDIT_MODE;
    },

    editCustomButton(buttonOrder) {
      this.customButtonToEdit = { ...this.customButtons[buttonOrder] };
      this.currentSubpanel = SUBPANELS.CUSTOM_BUTTON_EDIT_MODE;
    },

    removeCustomButton(buttonOrder) {
      this.customButtons.splice(buttonOrder, 1);
      this.customButtons.forEach((customButton, index) => {
        customButton[this.CUSTOM_BUTTON_FIELDS.ORDER] = index;
      });
    },

    updateCustomButton(customButton) {
      const orderKey = this.CUSTOM_BUTTON_FIELDS.ORDER;
      if (customButton[orderKey] === this.customButtons.length) {
        this.customButtons.push(customButton);
      } else {
        this.customButtons = this.customButtons.map(obj => (obj[orderKey] === customButton[orderKey] && customButton) || obj);
      }
    },

    editCustomButtonTranslationKey(customButtonField) {
      this.customButtonFieldAwaitingTranslationKey = customButtonField;
      this.$root.$emit("select-translation-key");
    },

    onTranslationKeySelected(translationKey) {
      if (this.customButtonFieldAwaitingTranslationKey) {
        this.$set(this.customButtonToEdit, this.customButtonFieldAwaitingTranslationKey, translationKey);
      } else if (this.awaitingFieldForTranslationKey === LpConfigConstants.NAVBAR_FIELDS.BACKGROUND_IMAGE_URL) {
        this.backgroundImageUrl = translationKey;
      }
      this.customButtonFieldAwaitingTranslationKey = null;
      this.awaitingFieldForTranslationKey = null;
    },

    onCustomButtonTypeSelected(index) {
      this.$set(this.customButtonToEdit, this.CUSTOM_BUTTON_FIELDS.TYPE, this.customButtonTypes[index]);
    },

    onCustomButtonTargetSelected(index) {
      this.$set(this.customButtonToEdit, this.CUSTOM_BUTTON_FIELDS.TARGET, this.customButtonTargets[index]);
    },
  },

  watch: {
    navbar: {
      immediate: true,
      handler: function(newValue) {
        const navbarFields = LpConfigConstants.NAVBAR_FIELDS;
        this.logoWidth = newValue[navbarFields.LOGO_WIDTH];
        this.logoBackgroundColor = newValue[navbarFields.LOGO_BACKGROUND_COLOR];
        this.logoWithOutline = newValue[navbarFields.LOGO_WITH_OUTLINE];
        this.backgroundColor = newValue[navbarFields.BACKGROUND_COLOR];
        this.backgroundImageUrl = newValue[navbarFields.BACKGROUND_IMAGE_URL];
        this.backgroundImageSize = newValue[navbarFields.BACKGROUND_IMAGE_SIZE];
        this.backgroundImageRepeat = newValue[navbarFields.BACKGROUND_IMAGE_REPEAT];
        this.buttonTextColor = newValue[navbarFields.BUTTON_TEXT_COLOR];
        this.iconColor = newValue[navbarFields.ICON_COLOR];
        this.customButtons = [...newValue[navbarFields.CUSTOM_BUTTONS]];
      },
    },
  },
};
</script>

<style scoped lang="scss">
.edit-button {
  display: flex;
  width: 28px;
  height: 28px;
  border-radius: 5px;
  padding: 6px;
  cursor: pointer;

  &:hover {
    background-color: lightgrey;
  }
}
</style>
