<template>
  <div>
    <quiz-item v-for="quiz in quizzes" :key="quiz.id" :quiz="quiz" :is-small="true"
               @start-quiz="$emit('start-quiz', $event)"></quiz-item>
  </div>
</template>

<script>
import QuizItem from "@/web/components/quizzes/QuizItem";

export default {
  name: "QuizzesList",

  props: ["quizzes"],

  components: {QuizItem},
};
</script>

<style scoped></style>
