<template>
  <!--MODULE SPECIFIC FIELDS-->
  <div class="mt-1">
    <!--CAROUSEL DISABLED-->
    <b-field class="mt-1">
      <b-switch v-model="$data[moduleFields.CAROUSEL_DISABLED]">{{ $t("admin_panel.partners_carousel_disabled") }}
      </b-switch>
    </b-field>

    <!--OPEN EXTERNAL LINK-->
    <b-field class="mt-1">
      <b-switch v-model="$data[moduleFields.OPEN_EXTERNAL_LINK]">{{ $t("admin_panel.partners_open_external_link") }}
      </b-switch>
    </b-field>

    <!--CAROUSEL BG COLOR-->
    <div class="field">
      <label class="label has-text-primary">{{ $t("admin_panel.partners_carousel_background_color") }}</label>
      <div class="control">
        <input
          class="input is-simple"
          type="color"
          :placeholder="$t('admin_panel.partners_carousel_background_color')"
          v-model="$data[moduleFields.CAROUSEL_BACKGROUND_COLOR]"
        />
      </div>
      <template>
        <p v-if="!$v[moduleFields.CAROUSEL_BACKGROUND_COLOR].hexColor" class="help is-danger">
          {{ $t("admin_panel.error_hex_color") }}</p>
      </template>
    </div>


    <!--CARD BG COLOR-->
    <div class="field">
      <label class="label has-text-primary">{{ $t("admin_panel.partners_card_background_color") }}</label>
      <div class="control">
        <input
          class="input is-simple"
          type="color"
          :placeholder="$t('admin_panel.partners_card_background_color')"
          v-model="$data[moduleFields.CARD_BACKGROUND_COLOR]"
        />
      </div>
      <template>
        <p v-if="!$v[moduleFields.CARD_BACKGROUND_COLOR].hexColor" class="help is-danger">
          {{ $t("admin_panel.error_hex_color") }}</p>
      </template>
    </div>
  </div>
</template>

<script>
import LpConfigConstants from "@/shared/constants/lp-config-constants";
import { hexColor } from "@/shared/utils/form-validations";

export default {
  name: "AdminModuleConfigPartners",

  props: {
    value: {
      type: Object,
      required: true,
    },
  },

  data() {
    const moduleFields = LpConfigConstants.PARTNERS_MODULE_FIELDS;
    return {
      [moduleFields.CAROUSEL_DISABLED]: this.value[moduleFields.CAROUSEL_DISABLED],
      [moduleFields.OPEN_EXTERNAL_LINK]: this.value[moduleFields.OPEN_EXTERNAL_LINK],
      [moduleFields.CARD_BACKGROUND_COLOR]: this.value[moduleFields.CARD_BACKGROUND_COLOR],
      [moduleFields.CAROUSEL_BACKGROUND_COLOR]: this.value[moduleFields.CAROUSEL_BACKGROUND_COLOR],
    };
  },

  validations() {
    const moduleFields = LpConfigConstants.PARTNERS_MODULE_FIELDS;

    return {
      [moduleFields.CARD_BACKGROUND_COLOR]: {hexColor},
      [moduleFields.CAROUSEL_BACKGROUND_COLOR]: {hexColor},
    };
  },

  computed: {
    moduleFields: () => LpConfigConstants.PARTNERS_MODULE_FIELDS,

    specificFieldsObject() {
      return Object.values(this.moduleFields).reduce((acc, fieldKey) => {
        return {...acc, [fieldKey]: this.$data[fieldKey]};
      }, {});
    },
  },

  watch: {
    specificFieldsObject: {
      immediate: false,
      handler(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
};
</script>

<style scoped></style>
