<template>
  <div class="box">
    <div class="is-flex is-flex-direction-row is-align-items-center is-justify-content-center mb-2">
      <div class="is-size-5 mr-1">{{ $t("admin_panel.help_configuration_title") }}</div>
      <b-button type="is-primary" class="is-small" @click="save">{{ $t("admin_panel.save") }}</b-button>
    </div>

    <!--ENABLED-->
    <b-field>
      <b-switch v-model="$data[helpFields.ENABLED]">{{ $t("admin_panel.help_enabled_title") }}</b-switch>
    </b-field>

    <!--CUSTOM SUPPORT USER UUID-->
    <div class="field">
      <label class="label has-text-primary">{{ $t("admin_panel.help_custom_support_title") }}</label>
      <b-button type="is-primary" class="admin-translation-key-button" @click="editCustomerSupportUserUuid">
        <div class="is-flex-direction-row is-flex is-justify-content-center is-align-items-center">
          <profile-picture
            v-if="$data[helpFields.CUSTOM_SUPPORT_USER_UUID]"
            class="is-28x28 mr-1"
            :picture="selectedCustomerSupportUserPicture"
            :initials="selectedCustomerSupportUserInitials"
          ></profile-picture>
          <div>{{ customSupportUserTitle }}</div>
        </div>
      </b-button>
    </div>

    <!--FEEDBACK TITLE-->
    <div class="field">
      <label class="label has-text-primary">{{ $t("admin_panel.help_feedback_title") }}</label>
      <b-button type="is-primary" class="admin-translation-key-button" @click="editFeedbackTitle">
        {{ feedbackTitleTranslationKey }}
      </b-button>
    </div>

    <!--FEEDBACK DESCRIPTION-->
    <div class="field">
      <label class="label has-text-primary">{{ $t("admin_panel.help_feedback_description") }}</label>
      <b-button type="is-primary" class="admin-translation-key-button" @click="editFeedbackDescription">
        {{ feedbackDescriptionTranslationKey }}
      </b-button>
    </div>

    <!--LIVECHAT TITLE-->
    <div class="field">
      <label class="label has-text-primary">{{ $t("admin_panel.help_livechat_title") }}</label>
      <b-button type="is-primary" class="admin-translation-key-button" @click="editLivechatTitle">
        {{ livechatTranslationKey }}
      </b-button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import LpConfigConstants from "@/shared/constants/lp-config-constants";
import ProfilePicture from "@/shared/components/common/profile/ProfilePicture";
import { fullName, initials } from "@/shared/utils";

export default {
  name: "AdminHelpConfiguration",
  components: { ProfilePicture },
  data() {
    const helpFields = LpConfigConstants.HELP_FIELDS;

    return {
      [helpFields.ENABLED]: false,
      [helpFields.CUSTOM_SUPPORT_USER_UUID]: null,
      [helpFields.FEEDBACK_TITLE]: null,
      [helpFields.FEEDBACK_DESCRIPTION]: null,
      [helpFields.LIVECHAT_TITLE]: null,

      awaitingFieldForTranslationKey: null,
      awaitingFieldForUserUuid: null,
    };
  },

  created() {
    this.$root.$on("translation-key-selected", this.onTranslationKeySelected);
    this.$root.$on("user-uuid-selected", this.onUserUuidSelected);
  },

  beforeDestroy() {
    this.$root.$off("translation-key-selected", this.onTranslationKeySelected);
    this.$root.$off("user-uuid-selected", this.onUserUuidSelected);
  },

  computed: {
    ...mapState("adminPanel", ["help"]),
    ...mapGetters("attendees", ["getUserById"]),

    helpFields: () => LpConfigConstants.HELP_FIELDS,

    feedbackTitleTranslationKey() {
      return this.$data[this.helpFields.FEEDBACK_TITLE] || this.$t("admin_panel.select_translation_key");
    },

    feedbackDescriptionTranslationKey() {
      return this.$data[this.helpFields.FEEDBACK_DESCRIPTION] || this.$t("admin_panel.select_translation_key");
    },

    livechatTranslationKey() {
      return this.$data[this.helpFields.LIVECHAT_TITLE] || this.$t("admin_panel.select_translation_key");
    },

    customSupportUserTitle() {
      return this.selectedCustomerSupportUserFullName || this.$t("admin_panel.select_user");
    },

    selectedCustomerSupportUser() {
      const userUuid = this.$data[this.helpFields.CUSTOM_SUPPORT_USER_UUID];
      return userUuid && this.getUserById(userUuid);
    },

    selectedCustomerSupportUserFullName() {
      return this.selectedCustomerSupportUser && fullName(this.selectedCustomerSupportUser);
    },

    selectedCustomerSupportUserPicture() {
      return this.selectedCustomerSupportUser && this.selectedCustomerSupportUser.picture;
    },

    selectedCustomerSupportUserInitials() {
      return this.selectedCustomerSupportUser && initials(this.selectedCustomerSupportUser);
    },
  },

  methods: {
    ...mapMutations("adminPanel", ["setHelpConfig"]),

    save() {
      this.setHelpConfig(this.getHelpConfig());
      this.$root.$emit("close-side-panel");
      this.$emit("navigate-back");
    },

    getHelpConfig() {
      const helpFields = LpConfigConstants.HELP_FIELDS;
      return {
        [helpFields.ENABLED]: this.$data[helpFields.ENABLED],
        [helpFields.LIVECHAT_TITLE]: this.$data[helpFields.LIVECHAT_TITLE],
        [helpFields.FEEDBACK_TITLE]: this.$data[helpFields.FEEDBACK_TITLE],
        [helpFields.FEEDBACK_DESCRIPTION]: this.$data[helpFields.FEEDBACK_DESCRIPTION],
        [helpFields.CUSTOM_SUPPORT_USER_UUID]: this.$data[helpFields.CUSTOM_SUPPORT_USER_UUID],
      };
    },

    editFeedbackTitle() {
      this.awaitingFieldForTranslationKey = this.helpFields.FEEDBACK_TITLE;
      this.$root.$emit("select-translation-key");
    },

    editFeedbackDescription() {
      this.awaitingFieldForTranslationKey = this.helpFields.FEEDBACK_DESCRIPTION;
      this.$root.$emit("select-translation-key");
    },

    editLivechatTitle() {
      this.awaitingFieldForTranslationKey = this.helpFields.LIVECHAT_TITLE;
      this.$root.$emit("select-translation-key");
    },

    editCustomerSupportUserUuid() {
      this.awaitingFieldForUserUuid = this.helpFields.CUSTOM_SUPPORT_USER_UUID;
      this.$root.$emit("select-user-uuid");
    },

    onTranslationKeySelected(translationKey) {
      if (this.awaitingFieldForTranslationKey) {
        this.$data[this.awaitingFieldForTranslationKey] = translationKey;
      }
      this.awaitingFieldForTranslationKey = null;
    },

    onUserUuidSelected(userUuid) {
      if (this.awaitingFieldForUserUuid === this.helpFields.CUSTOM_SUPPORT_USER_UUID) {
        this.$data[this.helpFields.CUSTOM_SUPPORT_USER_UUID] = userUuid;
      }
      this.awaitingFieldForUserUuid = null;
    },
  },

  watch: {
    help: {
      immediate: true,
      handler: function(newValue) {
        const helpFields = LpConfigConstants.HELP_FIELDS;
        this.$data[helpFields.ENABLED] = newValue[helpFields.ENABLED];
        this.$data[helpFields.CUSTOM_SUPPORT_USER_UUID] = newValue[helpFields.CUSTOM_SUPPORT_USER_UUID];
        this.$data[helpFields.FEEDBACK_TITLE] = newValue[helpFields.FEEDBACK_TITLE];
        this.$data[helpFields.FEEDBACK_DESCRIPTION] = newValue[helpFields.FEEDBACK_DESCRIPTION];
        this.$data[helpFields.LIVECHAT_TITLE] = newValue[helpFields.LIVECHAT_TITLE];
      },
    },
  },
};
</script>

<style scoped></style>
