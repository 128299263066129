<template>
  <div>
    <inbox-thread-list-item
      v-for="thread in threads"
      :thread="thread"
      :current-user-uuid="currentUserUuid"
      :key="thread.id"
      :id="`thread-${thread.id}`"
      :selected="false"
      :is-small="true"
      @thread-selected="$emit('thread-selected', $event)"
    ></inbox-thread-list-item>
  </div>
</template>

<script>
import InboxThreadListItem from "@/web/components/inbox/InboxThreadListItem";

export default {
  name: "InboxBubbleContactList",

  components: {InboxThreadListItem},

  props: ["threads", "currentUserUuid"],
};
</script>

<style scoped></style>
