<template>
  <div class="box">
    <div class="is-flex is-flex-direction-row is-align-items-center is-justify-content-center mb-2">
      <div class="is-size-5 mr-1">{{ $t("admin_panel.colors_configuration_title") }}</div>
      <b-button type="is-primary" class="is-small" @click="save">{{ $t("admin_panel.save") }}</b-button>
    </div>

    <!--THEME-->
    <div class="field">
      <label class="label has-text-primary">{{ $t("admin_panel.colors_theme_title") }}</label>
      <div class="control">
        <dropdown-select-wrapper
          :items="themeTypeValues"
          v-model="$data[themeFields.THEME_TYPE]"
          :unselectable="false"
        ></dropdown-select-wrapper>
      </div>
    </div>

    <!--FONT-->
    <div class="field">
      <label class="label has-text-primary">{{ $t("admin_panel.colors_font_title") }}</label>
      <div class="control">
        <dropdown-select-wrapper :items="fontTypeValues" v-model="$data[themeFields.FONT]" :unselectable="false"></dropdown-select-wrapper>
      </div>
    </div>

    <!--SIDE MENU TYPE-->
    <div class="field">
      <label class="label has-text-primary">{{ $t("admin_panel.colors_side_menu_types_title") }}</label>
      <div class="control">
        <dropdown-select-wrapper
          :items="sideMenuTypes"
          v-model="$data[themeFields.SIDE_MENU_TYPE]"
          :unselectable="false"
        ></dropdown-select-wrapper>
      </div>
    </div>

    <!--MAIN COLOR-->
    <div class="field">
      <label class="label has-text-primary">{{ $t("admin_panel.colors_main_color_title") }}</label>
      <div class="control">
        <input
          class="input is-simple"
          type="color"
          :placeholder="$t('admin_panel.colors_main_color_title')"
          v-model="$data[themeFields.MAIN_COLOR]"
        />
      </div>
      <template>
        <p v-if="!$v[themeFields.MAIN_COLOR].hexColor" class="help is-danger">{{ $t("admin_panel.error_hex_color") }}</p>
      </template>
    </div>

    <!--ACCENT COLOR-->
    <div class="field">
      <label class="label has-text-primary">{{ $t("admin_panel.colors_accent_color_title") }}</label>
      <div class="control">
        <input
          class="input is-simple"
          type="color"
          :placeholder="$t('admin_panel.colors_accent_color_title')"
          v-model="$data[themeFields.ACCENT_COLOR]"
        />
      </div>
      <template>
        <p v-if="!$v[themeFields.ACCENT_COLOR].hexColor" class="help is-danger">{{ $t("admin_panel.error_hex_color") }}</p>
      </template>
    </div>

    <!--PRIMARY TEXT COLOR-->
    <div class="field">
      <label class="label has-text-primary">{{ $t("admin_panel.colors_primary_text_color_title") }}</label>
      <div class="control">
        <input
          class="input is-simple"
          type="color"
          :placeholder="$t('admin_panel.colors_primary_text_color_title')"
          v-model="$data[themeFields.PRIMARY_TEXT_COLOR]"
        />
      </div>
      <template>
        <p v-if="!$v[themeFields.PRIMARY_TEXT_COLOR].hexColor" class="help is-danger">
          {{ $t("admin_panel.error_hex_color") }}
        </p>
      </template>
    </div>

    <!--SECONDARY TEXT COLOR-->
    <div class="field">
      <label class="label has-text-primary">{{ $t("admin_panel.colors_secondary_text_color_title") }}</label>
      <div class="control">
        <input
          class="input is-simple"
          type="color"
          :placeholder="$t('admin_panel.colors_secondary_text_color_title')"
          v-model="$data[themeFields.SECONDARY_TEXT_COLOR]"
        />
      </div>
      <template>
        <p v-if="!$v[themeFields.SECONDARY_TEXT_COLOR].hexColor" class="help is-danger">
          {{ $t("admin_panel.error_hex_color") }}
        </p>
      </template>
    </div>

    <!--BACKGROUND COLOR-->
    <div class="field">
      <label class="label has-text-primary">{{ $t("admin_panel.colors_background_color_title") }}</label>
      <div class="control">
        <input
          class="input is-simple"
          type="color"
          :placeholder="$t('admin_panel.colors_background_color_title')"
          v-model="$data[themeFields.BACKGROUND_COLOR]"
        />
      </div>
      <template>
        <p v-if="!$v[themeFields.BACKGROUND_COLOR].hexColor" class="help is-danger">
          {{ $t("admin_panel.error_hex_color") }}
        </p>
      </template>
    </div>

    <!--NAVBAR BACKGROUND COLOR-->
    <div class="field">
      <label class="label has-text-primary">{{ $t("admin_panel.colors_navbar_background_color_title") }}</label>
      <div class="control">
        <input
          class="input is-simple"
          type="color"
          :placeholder="$t('admin_panel.colors_navbar_background_color_title')"
          v-model="$data[themeFields.NAVBAR_BACKGROUND_COLOR]"
        />
      </div>
      <template>
        <p v-if="!$v[themeFields.BACKGROUND_COLOR].hexColor" class="help is-danger">
          {{ $t("admin_panel.error_hex_color") }}
        </p>
      </template>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import LpConfigConstants from "@/shared/constants/lp-config-constants";
import { hexColor } from "@/shared/utils/form-validations";
import DropdownSelectWrapper from "@/shared/components/form/DropdownSelectWrapper";

export default {
  name: "AdminThemeConfiguration",
  components: { DropdownSelectWrapper },

  data() {
    const themeFields = LpConfigConstants.THEME_FIELDS;

    return {
      [themeFields.THEME_TYPE]: null,
      [themeFields.SIDE_MENU_TYPE]: null,
      [themeFields.FONT]: null,
      [themeFields.MAIN_COLOR]: null,
      [themeFields.ACCENT_COLOR]: null,
      [themeFields.PRIMARY_TEXT_COLOR]: null,
      [themeFields.SECONDARY_TEXT_COLOR]: null,
      [themeFields.BACKGROUND_COLOR]: null,
      [themeFields.NAVBAR_BACKGROUND_COLOR]: null,
    };
  },

  validations() {
    const themeFields = LpConfigConstants.THEME_FIELDS;

    return {
      [themeFields.MAIN_COLOR]: { hexColor },
      [themeFields.ACCENT_COLOR]: { hexColor },
      [themeFields.PRIMARY_TEXT_COLOR]: { hexColor },
      [themeFields.SECONDARY_TEXT_COLOR]: { hexColor },
      [themeFields.BACKGROUND_COLOR]: { hexColor },
      [themeFields.NAVBAR_BACKGROUND_COLOR]: { hexColor },
    };
  },

  computed: {
    ...mapState("adminPanel", ["theme"]),

    themeFields: () => LpConfigConstants.THEME_FIELDS,

    themeTypeValues() {
      return Object.values(LpConfigConstants.THEME_TYPES);
    },

    fontTypeValues() {
      return Object.values(LpConfigConstants.FONT_TYPES);
    },

    sideMenuTypes() {
      return Object.values(LpConfigConstants.SIDE_MENU_TYPES);
    },
  },

  methods: {
    ...mapMutations("adminPanel", ["setThemeConfig"]),

    save() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.setThemeConfig(this.getThemeConfig());
        this.$emit("navigate-back");
      }
    },

    getThemeConfig() {
      const themeFields = LpConfigConstants.THEME_FIELDS;
      return {
        [themeFields.THEME_TYPE]: this.$data[themeFields.THEME_TYPE],
        [themeFields.SIDE_MENU_TYPE]: this.$data[themeFields.SIDE_MENU_TYPE],
        [themeFields.FONT]: this.$data[themeFields.FONT],
        [themeFields.MAIN_COLOR]: this.$data[themeFields.MAIN_COLOR],
        [themeFields.ACCENT_COLOR]: this.$data[themeFields.ACCENT_COLOR],
        [themeFields.PRIMARY_TEXT_COLOR]: this.$data[themeFields.PRIMARY_TEXT_COLOR],
        [themeFields.SECONDARY_TEXT_COLOR]: this.$data[themeFields.SECONDARY_TEXT_COLOR],
        [themeFields.BACKGROUND_COLOR]: this.$data[themeFields.BACKGROUND_COLOR],
        [themeFields.NAVBAR_BACKGROUND_COLOR]: this.$data[themeFields.NAVBAR_BACKGROUND_COLOR],
      };
    },
  },

  watch: {
    theme: {
      immediate: true,
      handler: function (newValue) {
        const themeFields = LpConfigConstants.THEME_FIELDS;

        this.$data[themeFields.THEME_TYPE] = newValue[themeFields.THEME_TYPE] ?? LpConfigConstants.THEME_TYPES.SIMPLE_DAY;
        this.$data[themeFields.SIDE_MENU_TYPE] = newValue[themeFields.SIDE_MENU_TYPE] ?? LpConfigConstants.SIDE_MENU_TYPES.BUBBLES;
        this.$data[themeFields.FONT] = newValue[themeFields.FONT] ?? LpConfigConstants.FONT_TYPES.LATO;
        this.$data[themeFields.MAIN_COLOR] = newValue[themeFields.MAIN_COLOR];
        this.$data[themeFields.ACCENT_COLOR] = newValue[themeFields.ACCENT_COLOR];
        this.$data[themeFields.PRIMARY_TEXT_COLOR] = newValue[themeFields.PRIMARY_TEXT_COLOR];
        this.$data[themeFields.SECONDARY_TEXT_COLOR] = newValue[themeFields.SECONDARY_TEXT_COLOR];
        this.$data[themeFields.BACKGROUND_COLOR] = newValue[themeFields.BACKGROUND_COLOR];
        this.$data[themeFields.NAVBAR_BACKGROUND_COLOR] = newValue[themeFields.NAVBAR_BACKGROUND_COLOR];
      },
    },
  },
};
</script>

<style scoped></style>
