<template>
  <div>
    <!--TYPE-->
    <div class="field">
      <label class="label has-text-primary mb-1">{{ $t("admin_panel.module_type") }}</label>
      <dropdown-select :items="moduleTypes" :value="currentTypeIndex" @input="onNewTypeSelected">
        <template #dropdown="{ item }">
          <span>{{ item }}</span>
        </template>

        <template #placeholder>
          <span class="has-text-secondary">{{ $t("common.select_from_list") }}</span>
        </template>
      </dropdown-select>
    </div>

    <!--VISIBLE-->
    <b-field class="mt-1">
      <b-switch v-model="$data[moduleFields.VISIBLE]">{{ $t("admin_panel.module_visible") }}</b-switch>
    </b-field>

    <!--ONLY FOR LOGGED IN-->
    <b-field>
      <b-switch v-model="$data[moduleFields.ONLY_FOR_LOGGED_IN]">{{ $t("admin_panel.module_visible_for_logged_in") }}
      </b-switch>
    </b-field>

    <!--ONLY FOR GUESTS-->
    <b-field v-if="onlyForGuestsVisible">
      <b-switch v-model="$data[moduleFields.ONLY_FOR_GUESTS]">{{ $t("admin_panel.module_visible_for_guests") }}
      </b-switch>
    </b-field>

    <!--SEPARATOR DISABLED-->
    <b-field>
      <b-switch v-model="$data[moduleFields.SEPARATOR_DISABLED]">{{ $t("admin_panel.module_separator_disabled") }}
      </b-switch>
    </b-field>

    <!--TITLE-->
    <div class="field">
      <label class="label has-text-primary">{{ $t("admin_panel.module_title") }}</label>
      <b-button type="is-primary" class="admin-translation-key-button" @click="editTitle">
        {{ titleLabel }}
      </b-button>
    </div>

    <!--NAVBAR TITLE-->
    <div class="field mt-1">
      <label class="label has-text-primary">{{ $t("admin_panel.module_navbar_title") }}</label>
      <b-button type="is-primary" class="admin-translation-key-button" @click="editNavbarTitle">
        {{ navbarTitleLabel }}
      </b-button>
    </div>

    <!--NAVBAR ACTION-->
    <div class="mt-1" v-if="$data[moduleFields.NAVBAR_TITLE] && navbarActionVisible">
      <label class="label has-text-primary">{{ $t("admin_panel.module_navbar_action") }}</label>
      <navbar-action-picker v-model="$data[moduleFields.NAVBAR_ACTION]"></navbar-action-picker>
    </div>

    <!--COMPONENT ID-->
    <div class="field mt-1">
      <label class="label has-text-primary mb-1">{{
          $t("admin_panel.module_component_id_visibility")
        }}</label>
      <dropdown-select-wrapper
        :items="allComponents"
        v-model="$data[moduleFields.COMPONENT_ID]"
        :item-label-function="(item) => item.label"
        :item-value-function="(item) => item.id"
      ></dropdown-select-wrapper>
    </div>

    <!--ORDER-->
    <div class="field">
      <label class="label has-text-primary">{{ $t("admin_panel.module_order") }}</label>
      <div class="control">
        <input class="input is-simple" :disabled="true" type="number" :placeholder="$t('admin_panel.module_order')"
               v-model="$data[moduleFields.ORDER]"/>
      </div>
    </div>
  </div>
</template>

<script>
import DropdownSelectWrapper from "@/shared/components/form/DropdownSelectWrapper";
import DropdownSelect from "@/shared/components/DropdownSelect";
import LpConfigConstants from "@/shared/constants/lp-config-constants";
import NavbarActionPicker from "@/web/components/admin/subpanels/modules/views/NavbarActionPicker";
import { mapGetters } from "vuex";

export default {
  name: "AdminModuleConfigCommon",

  components: { DropdownSelect, DropdownSelectWrapper, NavbarActionPicker },

  props: {
    value: {
      type: Object,
      required: false,
    },
  },

  data() {
    const moduleFields = LpConfigConstants.COMMON_MODULE_FIELDS;
    return {
      [moduleFields.VISIBLE]: this.value[moduleFields.VISIBLE] ?? true,
      [moduleFields.ONLY_FOR_LOGGED_IN]: this.value[moduleFields.ONLY_FOR_LOGGED_IN] ?? false,
      [moduleFields.ONLY_FOR_GUESTS]: this.value[moduleFields.ONLY_FOR_GUESTS] ?? false,
      [moduleFields.TITLE]: this.value[moduleFields.TITLE],
      [moduleFields.TYPE]: this.value[moduleFields.TYPE],
      [moduleFields.COMPONENT_ID]: this.value[moduleFields.COMPONENT_ID],
      [moduleFields.ORDER]: this.value[moduleFields.ORDER],
      [moduleFields.NAVBAR_TITLE]: this.value[moduleFields.NAVBAR_TITLE],
      [moduleFields.NAVBAR_ACTION]: this.value[moduleFields.NAVBAR_ACTION],
      [moduleFields.SEPARATOR_DISABLED]: this.value[moduleFields.SEPARATOR_DISABLED],

      currentTypeIndex: Object.values(LpConfigConstants.MODULE_TYPES).indexOf(this.value[moduleFields.TYPE]),
      awaitingFieldForTranslationKey: null,
    };
  },

  created() {
    this.$root.$on("translation-key-selected", this.onTranslationKeySelected);
  },

  beforeDestroy() {
    this.$root.$off("translation-key-selected", this.onTranslationKeySelected);
  },

  computed: {
    ...mapGetters("components", ["allComponents"]),

    moduleFields: () => LpConfigConstants.COMMON_MODULE_FIELDS,

    titleLabel() {
      return this.$data[this.moduleFields.TITLE] || this.$t("admin_panel.select_translation_key");
    },

    navbarTitleLabel() {
      return this.$data[this.moduleFields.NAVBAR_TITLE] || this.$t("admin_panel.select_translation_key");
    },

    moduleTypes() {
      return Object.values(LpConfigConstants.MODULE_TYPES);
    },

    specificFieldsObject() {
      return Object.values(this.moduleFields).reduce((acc, fieldKey) => {
        return { ...acc, [fieldKey]: this.$data[fieldKey] };
      }, {});
    },

    navbarActionVisible() {
      let moduleFields = LpConfigConstants.COMMON_MODULE_FIELDS;
      let moduleTypes = LpConfigConstants.MODULE_TYPES;
      return this.$data[moduleFields.TYPE] === moduleTypes.COMPONENT_QUIZZES ||
        this.$data[moduleFields.TYPE] === moduleTypes.COMPONENT_ATTENDEES;
    },

    onlyForGuestsVisible() {
      let moduleFields = LpConfigConstants.COMMON_MODULE_FIELDS;
      let moduleTypes = LpConfigConstants.MODULE_TYPES;
      return this.$data[moduleFields.TYPE] !== moduleTypes.COMPONENT_ATTENDEES
        && this.$data[moduleFields.TYPE] !== moduleTypes.COMPONENT_QUIZZES;
    },
  },

  methods: {
    editTitle() {
      this.awaitingFieldForTranslationKey = this.moduleFields.TITLE;
      this.$root.$emit("select-translation-key");
    },

    editNavbarTitle() {
      this.awaitingFieldForTranslationKey = this.moduleFields.NAVBAR_TITLE;
      this.$root.$emit("select-translation-key");
    },

    onNewTypeSelected(typeIndex) {
      this.currentTypeIndex = typeIndex;
      this.$data[this.moduleFields.TYPE] = this.moduleTypes[typeIndex];
    },

    onNewComponentId(selectedComponent) {
      if (selectedComponent) {
        this.$data[this.moduleFields.COMPONENT_ID] = selectedComponent.id;
      } else {
        this.$data[this.moduleFields.COMPONENT_ID] = null;
      }
    },

    onTranslationKeySelected(translationKey) {
      if (this.awaitingFieldForTranslationKey) {
        this.$data[this.awaitingFieldForTranslationKey] = translationKey;
      }
      this.awaitingFieldForTranslationKey = null;
    },
  },

  watch: {
    [LpConfigConstants.COMMON_MODULE_FIELDS.ONLY_FOR_LOGGED_IN]: {
      immediate: false,
      handler: function(newValue) {
        if (newValue) {
          this.$data[LpConfigConstants.COMMON_MODULE_FIELDS.ONLY_FOR_GUESTS] = false;
        }
      },
    },

    [LpConfigConstants.COMMON_MODULE_FIELDS.ONLY_FOR_GUESTS]: {
      immediate: false,
      handler: function(newValue) {
        if (newValue) {
          this.$data[LpConfigConstants.COMMON_MODULE_FIELDS.ONLY_FOR_LOGGED_IN] = false;
        }
      },
    },

    specificFieldsObject: {
      immediate: false,
      handler(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
};
</script>

<style scoped></style>
