<template>
  <div class="box">
    <div class="is-flex is-flex-direction-row is-align-items-center is-justify-content-center mb-2">
      <div class="is-size-5 mr-1">{{ $t("admin_panel.footer_configuration_title") }}</div>
      <b-button type="is-primary" class="is-small" @click="save">{{ $t("admin_panel.save") }}</b-button>
    </div>

    <!--BACKGROUND COLOR-->
    <div class="field">
      <label class="label has-text-primary">{{ $t("admin_panel.footer_background_color") }}</label>
      <div class="control">
        <input
          class="input is-simple"
          type="color"
          :placeholder="$t('admin_panel.footer_background_color')"
          v-model="$data[footerFields.BACKGROUND_COLOR]"
        />
      </div>
      <template>
        <p v-if="!$v[footerFields.BACKGROUND_COLOR].hexColor" class="help is-danger">{{ $t("admin_panel.error_hex_color") }}</p>
      </template>
    </div>

    <!--TEXT COLOR-->
    <div class="field">
      <label class="label has-text-primary">{{ $t("admin_panel.footer_text_color") }}</label>
      <div class="control">
        <input
          class="input is-simple"
          type="color"
          :placeholder="$t('admin_panel.footer_text_color')"
          v-model="$data[footerFields.TEXT_COLOR]"
        />
      </div>
      <template>
        <p v-if="!$v[footerFields.TEXT_COLOR].hexColor" class="help is-danger">{{ $t("admin_panel.error_hex_color") }}</p>
      </template>
    </div>

    <!--BUTTON HOVER TEXT COLOR-->
    <div class="field">
      <label class="label has-text-primary">{{ $t("admin_panel.footer_text_color_hover") }}</label>
      <div class="control">
        <input
          class="input is-simple"
          type="color"
          :placeholder="$t('admin_panel.footer_text_color')"
          v-model="$data[footerFields.TEXT_COLOR_HOVER]"
        />
      </div>
      <template>
        <p v-if="!$v[footerFields.TEXT_COLOR_HOVER].hexColor" class="help is-danger">{{ $t("admin_panel.error_hex_color") }}</p>
      </template>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import LpConfigConstants from "@/shared/constants/lp-config-constants";
import { hexColor } from "@/shared/utils/form-validations";

export default {
  name: "AdminFooterConfiguration",

  data() {
    const footerFields = LpConfigConstants.FOOTER_FIELDS;
    return {
      [footerFields.BACKGROUND_COLOR]: null,
      [footerFields.TEXT_COLOR]: null,
      [footerFields.TEXT_COLOR_HOVER]: null,
    };
  },

  validations() {
    return {
      [this.footerFields.BACKGROUND_COLOR]: { hexColor },
      [this.footerFields.TEXT_COLOR]: { hexColor },
      [this.footerFields.TEXT_COLOR_HOVER]: { hexColor },
    };
  },

  computed: {
    ...mapState("adminPanel", ["footer"]),

    footerFields: () => LpConfigConstants.FOOTER_FIELDS,
  },

  methods: {
    ...mapMutations("adminPanel", ["setFooterConfig"]),

    save() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.setFooterConfig(this.getFooterConfig());
        this.$emit("navigate-back");
      }
    },

    getFooterConfig() {
      return {
        [this.footerFields.BACKGROUND_COLOR]: this.$data[this.footerFields.BACKGROUND_COLOR],
        [this.footerFields.TEXT_COLOR]: this.$data[this.footerFields.TEXT_COLOR],
        [this.footerFields.TEXT_COLOR_HOVER]: this.$data[this.footerFields.TEXT_COLOR_HOVER],
      };
    },
  },

  watch: {
    footer: {
      immediate: true,
      handler: function (newValue) {
        this.$data[this.footerFields.BACKGROUND_COLOR] = newValue[this.footerFields.BACKGROUND_COLOR];
        this.$data[this.footerFields.TEXT_COLOR] = newValue[this.footerFields.TEXT_COLOR];
        this.$data[this.footerFields.TEXT_COLOR_HOVER] = newValue[this.footerFields.TEXT_COLOR_HOVER];
      },
    },
  },
};
</script>

<style scoped></style>
