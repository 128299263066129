<template>
  <div class="modal-card">
    <b-loading v-if="isPosting" :is-full-page="false" :active="isPosting"></b-loading>
    <header class="modal-card-head fw-thread-list-item px-3">
      <div class="columns is-mobile is-gapless mb-0 mt-3 is-vcentered has-fullwidth">
        <div class="column is-narrow">
          <profile-picture
            :picture="picture"
            :initials="initials"
            class="fw-thread-avatar"
            :class="isSmallClass"
            @click.native.stop="openUserProfile"
          ></profile-picture>
        </div>
        <div class="column">
          <div class="fw-thread-title-container" :class="isSmallClass">
            <div class="fw-thread-title" :class="isSmallClass">
              {{ fullName }}
            </div>
            <div class="fw-thread-subtitle" :class="isSmallClass">
              {{ positionAndCompany }}
            </div>
          </div>
        </div>
        <div class="column is-narrow is-flex">
          <div class="fw-thread-date" :class="isSmallClass">
            {{ $t("time.time_just_now_uppercase") }}
          </div>
        </div>
      </div>
      <button class="delete" type="button" @click="closeModal"></button>
    </header>
    <section class="modal-card-body modal-card-foot pt-2">
      <div class="control mb-1 has-fullwidth">
        <textarea class="textarea" v-model="message" :class="isSmallClass"></textarea>
        <template v-if="submitted">
          <p v-if="!$v.message.required" class="help is-danger has-fullwidth mb-1">{{
              $t("common.field_required_error")
            }}</p>
        </template>
      </div>

      <template v-if="!comment">
        <div class="is-relative has-fullwidth" v-if="pictureAttachment">
          <feed-wall-image class="mb-1 has-fullwidth" :attachment="pictureAttachment"></feed-wall-image>
          <div class="close-button" @click="deleteAttachment">
            <close-icon class="svg-icon"></close-icon>
          </div>
        </div>
        <template v-else>
          <label class="thread-picture-container is-relative">
            <add-icon class="thread-picture-add-icon mr-1" v-if="!pictureUploading"></add-icon>
            <div class="thread-picture-add-title has-text-secondary" v-if="!pictureUploading">
              {{ $t("edit_profile.add_photo") }}
            </div>
            <b-loading v-if="pictureUploading" :is-full-page="false" :active="pictureUploading"></b-loading>
            <input v-else type="file" accept="image/png, image/jpeg" @change="onNewPicture"
                   style="visibility: hidden; width: 0; height: 0"/>
          </label>
          <div class="thread-picture-add-subtitle has-text-secondary mb-1">
            {{ $t("common.photo_file_formats") }}
          </div>
        </template>
      </template>
      <div class="post-thread-buttons-container">
        <div class="button is-rounded is-danger" :class="isSmallClass" @click="closeModal">
          {{ $t("common.cancel") }}
        </div>
        <div class="button is-rounded is-primary" :class="isSmallClass" @click="tryToPost">
          <template v-if="thread || comment">
            {{ $t("feedwall.feedwall_edit_post_save") }}
          </template>
          <template v-else>
            {{ $t("common.send") }}
          </template>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ProfilePicture from "@/shared/components/common/profile/ProfilePicture";
import CloseIcon from "@/assets/icon_close.svg";
import AddIcon from "@/assets/icons/add_2.svg";
import {mapActions, mapGetters} from "vuex";
import FeedWallImage from "@/web/components/feedwall/FeedWallImage";
import {required} from "vuelidate/lib/validators";

export default {
  name: "FeedWallCreateThreadModal",

  components: {FeedWallImage, CloseIcon, ProfilePicture, AddIcon},

  props: {
    channelId: {
      type: Number,
      required: true,
    },

    thread: {
      type: Object,
    },

    comment: {
      type: Object,
    },
  },

  mounted() {
    if (this.thread) {
      this.message = this.thread.message;
      this.pictureAttachment = this.thread.image;
    }
    if (this.comment) {
      this.message = this.comment.message;
    }
  },

  data() {
    return {
      message: "",
      pictureUploading: false,
      isPosting: false,
      pictureAttachment: null,
      submitted: false,
    };
  },

  validations() {
    return {
      message: {required},
    };
  },

  computed: {
    ...mapGetters("currentUser", ["fullName", "positionAndCompany", "initials", "picture"]),

    isSmallClass() {
      // if (this.isSmall) {
      //   return "is-small";
      // } else {
      return "";
      // }
    },
  },

  methods: {
    ...mapActions("feedWallChannel", ["uploadThreadPicture", "postThread", "editThread"]),
    ...mapActions("feedWallThread", ["editComment"]),

    openUserProfile() {
      this.$root.openUserModal(this.userUuid);
    },

    onNewPicture(event) {
      const file = event.target.files && event.target.files[0];
      if (file) {
        this.pictureUploading = true;
        this.uploadThreadPicture(file)
          .then(attachment => (this.pictureAttachment = attachment))
          .finally(() => (this.pictureUploading = false));
      }
    },

    tryToPost() {
      if (!this.isPosting) {
        this.$v.$touch();
        this.$data.submitted = true;

        if (!this.$v.$invalid) {
          this.isPosting = true;
          const attachmentId = this.pictureAttachment && this.pictureAttachment.id;
          if (this.thread) {
            this.editThread({
              channelId: this.channelId,
              threadId: this.thread.id,
              message: this.message,
              attachmentId: attachmentId,
            })
              .then(result => this.closeModal())
              .finally(() => (this.isPosting = false));
          } else if (this.comment) {
            this.editComment({
              channelId: this.channelId,
              threadId: this.comment.feed_wall_thread_id,
              commentId: this.comment.id,
              message: this.message,
            })
              .then(result => this.closeModal())
              .finally(() => (this.isPosting = false));
          } else {
            this.postThread({channelId: this.channelId, message: this.message, attachmentId: attachmentId})
              .then(result => this.closeModal())
              .finally(() => (this.isPosting = false));
          }
        }
      }
    },

    deleteAttachment() {
      this.pictureAttachment = null;
    },

    closeModal() {
      this.$parent.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.fw-thread-list-item {
  background-color: var(--side-menu-background-color);
}
.thread-picture-container {
  height: 56px;
  width: 100%;
  border: 1px solid #828282;
  border-radius: 5px;
  padding: 16px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;

  &:hover {
    border: 1px solid var(--primary-color);
  }

  &:hover::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    background-color: var(--primary-color);
    opacity: 0.1;
  }
}

.thread-picture-add-icon {
  width: 22px;
  height: 22px;
}

.thread-picture-add-title {
  font-size: 18px;
  line-height: 130%;
}

.thread-picture-add-subtitle {
  font-size: 14px;
  line-height: 130%;
}

.post-thread-buttons-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.close-button {
  position: absolute;
  top: 20px;
  right: 20px;
}
</style>
