import { render, staticRenderFns } from "./AdminFooterConfiguration.vue?vue&type=template&id=79c3bfd3&scoped=true&"
import script from "./AdminFooterConfiguration.vue?vue&type=script&lang=js&"
export * from "./AdminFooterConfiguration.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79c3bfd3",
  null
  
)

export default component.exports