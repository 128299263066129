<template>
  <div
    class="bubble-box is-relative"
    :class="{ 'is-active': isActive, 'is-panel': isPanel }"
    ref="bubbleBox"
    @focusin="$emit('active')"
    @focusout="$emit('inactive')"
    tabindex="-1"
  >
    <div class="bubble-top-bar" v-if="!isPanel">
      <div class="bubble-title is-flex-grow-1">
        {{ title }}
      </div>
      <div class="is-flex is-flex-direction-row is-flex-shrink-1">
        <div class="add-icon has-background-primary mr-1" @click="add" v-if="addButtonVisible">
          <add-icon class="svg-icon white-icon-fill"></add-icon>
        </div>
        <div class="close-button mr-1" @click="navigateBack" v-if="backButtonVisible">
          <back-icon class="svg-icon icon-fill"></back-icon>
        </div>
        <div class="close-button mr-1" @click="minimize">
          <minimize-icon class="svg-icon"></minimize-icon>
        </div>
        <div class="close-button" @click="close">
          <close-icon class="svg-icon"></close-icon>
        </div>
      </div>
    </div>
    <div ref="fixedHeader" class="bubble-header" :style="backgroundColor">
      <slot name="fixed-header"></slot>
    </div>
    <transition name="fade" mode="out-in">
      <div
        key="messagesBubbleContent"
        v-if="vChatEnabled"
        class="bubble-content"
        :ref="bubbleContentRefId"
        :style="scrollableStyle"
        v-prevent-parent-scroll
        v-chat-scroll="{ always: false, smooth: true, notSmoothOnInit: true }"
        @v-chat-scroll-top-reached="onTopOfScroll"
      >
        <slot></slot>
      </div>
      <div v-else class="bubble-content" key="bubbleContent" :ref="bubbleContentRefId" :style="scrollableStyle" v-prevent-parent-scroll>
        <slot></slot>
      </div>
    </transition>
    <div ref="fixedFooter" class="bubble-footer" :style="backgroundColor">
      <slot name="fixed-footer"></slot>
    </div>
  </div>
</template>

<script>
import BackIcon from "@/assets/icon_back_arrow.svg";
import CloseIcon from "@/assets/icon_close.svg";
import MinimizeIcon from "@/assets/icon_minimize.svg";
import AddIcon from "@/assets/icons/add_2.svg";
import Constants from "@/web/constants";
import { throttle } from "@/shared/utils";

export default {
  name: "BubbleBox",

  components: { BackIcon, CloseIcon, MinimizeIcon, AddIcon },

  props: {
    title: {
      type: String,
      default: "",
    },

    isPanel: {
      type: Boolean,
      default: false,
    },

    isActive: {
      type: Boolean,
      required: true,
    },

    isFullHeight: {
      type: Boolean,
      default: true,
    },

    contentPadding: {
      type: String,
      default: "12px",
    },

    handleScroll: {
      type: Boolean,
      default: true,
    },

    backButtonVisible: {
      type: Boolean,
      default: false,
    },

    addButtonVisible: {
      type: Boolean,
      default: false,
    },

    vChatEnabled: {
      type: Boolean,
      default: false,
    },

    topScrollListenerEnabled: {
      type: Boolean,
      default: false,
    },

    isInitialized: {
      type: Boolean,
      default: false,
    },

    initScrollDirection: {
      type: String,
      default: "bottom",
    },

    initScrollEnabled: {
      type: Boolean,
      default: true,
    },

    bubbleId: {
      type: Number,
      required: true,
    },

    backgroundColor: {
      type: String,
    },

    debug: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      fixedHeaderHeight: 0,
      fixedFooterHeight: 0,
      scrollListener: null,
      initScroll: true,
      savedScrollHeight: null,
    };
  },

  mounted() {
    this.$nextTick(() => {
      this.$refs.bubbleBox.focus();
    });
  },

  computed: {
    Constants: () => Constants,

    scrollThreshold: () => 200,

    scrollableHeight() {
      const totalHeight = 420;
      const topBarHeight = 45;

      if (this.isFullHeight) {
        return totalHeight - topBarHeight;
      } else {
        return totalHeight - topBarHeight - this.fixedHeaderHeight - this.fixedFooterHeight;
      }
    },

    scrollableStyle() {
      return {
        maxHeight: this.isPanel ? "100%" : this.scrollableHeight + "px",
        height: this.isPanel ? "100%" : this.scrollableHeight + "px",
        overflowY: "auto",
        overflowX: "hidden",
        padding: this.contentPadding,
        backgroundColor: this.backgroundColor,
      };
    },

    backgroundStyle() {
      return {
        backgroundColor: this.backgroundColor,
      };
    },

    bubbleContentRefId() {
      return `bubbleContent-${this.bubbleId}`;
    },
  },

  watch: {
    title: {
      immediate: true,
      handler: function (newVal, oldVal) {
        setTimeout(() => {
          this.fixedHeaderHeight = this.$refs.fixedHeader.clientHeight;
          this.fixedFooterHeight = this.$refs.fixedFooter.clientHeight;
        }, 0);
      },
    },

    vChatEnabled: {
      immediate: true,
      handler: function (newVal) {
        if (!newVal) {
          this.initScroll = true;
          this.savedScrollHeight = null;
          const listElement = this.$refs[this.bubbleContentRefId];
          if (listElement) {
            listElement.scrollTop = 0;
          }
        }
        this.removeScrollListener();
        setTimeout(() => {
          this.addScrollListener();
        }, 100);
      },
    },
  },

  methods: {
    close() {
      this.$emit("close");
    },

    minimize() {
      this.$emit("minimize");
    },

    navigateBack() {
      this.$emit("navigate-back");
    },

    add() {
      this.$emit("add");
    },

    refreshLayout() {
      setTimeout(() => {
        this.fixedHeaderHeight = this.$refs.fixedHeader.clientHeight;
        this.fixedFooterHeight = this.$refs.fixedFooter.clientHeight;
      }, 0);
    },

    setInitScroll(isEnabled) {
      this.initScroll = isEnabled;
    },

    saveScrollPosition() {
      this.savedScrollHeight = this.$refs[this.bubbleContentRefId].scrollTop;
    },

    getSavedScrollPosition() {
      return this.savedScrollHeight;
    },

    setSavedScrollPosition(scrollPosition) {
      this.savedScrollHeight = scrollPosition;
    },

    restoreScrollPosition() {
      if (this.savedScrollHeight) {
        this.$nextTick(() => {
          if (this.debug) {
            console.log(`bubbleBox-restoreScrollPosition-${this.savedScrollHeight}`);
          }
          this.$refs[this.bubbleContentRefId].scrollTop = this.savedScrollHeight;
        });
      }
    },

    addScrollListener() {
      if (this.$refs[this.bubbleContentRefId] && !this.scrollListener) {
        this.scrollListener = ev => {
          const list = ev.target;
          let isOnBottomOfList = list.scrollTop > list.scrollHeight - list.clientHeight - this.scrollThreshold;
          if (isOnBottomOfList) {
            this.onBottomOfScroll();
          }
        };
        this.$refs[this.bubbleContentRefId].addEventListener("scroll", this.scrollListener);
      }
    },

    removeScrollListener() {
      if (this.scrollListener) {
        this.$refs[this.bubbleContentRefId].removeEventListener("scroll", this.scrollListener);
        this.scrollListener = null;
      }
    },

    onBottomOfScroll: throttle(function (newVal) {
      this.$emit("bottom-of-scroll");
    }, 1000),

    onTopOfScroll() {
      const listElement = this.$refs[this.bubbleContentRefId];
      if (listElement && this.topScrollListenerEnabled) {
        this.savedScrollHeight = listElement.scrollHeight;
        this.$emit("top-of-scroll");
      }
    },
  },

  beforeDestroy() {
    this.removeScrollListener();
  },

  updated() {
    //v-chat-scroll is handling scrolling for new elements and pagination callback, but somehow it doesn't want to scroll on the list initialization
    const listElement = this.$refs[this.bubbleContentRefId];
    if (listElement) {
      if (this.savedScrollHeight && this.vChatEnabled) {
        //SCROLL TO SAVED POSITION PRE PAGINATION REQUEST
        if (this.debug) {
          console.log(`bubbleBox-updated-savedScrollHeight&&vChatEnabled-scrollTop=${listElement.scrollHeight - this.savedScrollHeight}`);
        }
        listElement.scrollTop = listElement.scrollHeight - this.savedScrollHeight;
        this.savedScrollHeight = null;
      } else if (this.initScroll && this.initScrollEnabled) {
        //INITIAL SCROLL
        if (this.isInitialized) {
          this.initScroll = false;
        }
        setTimeout(() => {
          if (this.debug) {
            console.log("bubbleBox-initScroll");
          }
          if (this.initScrollDirection === "top") {
            listElement.scrollTop = 0;
          } else if (this.initScrollDirection === "bottom") {
            listElement.scrollTop = listElement.scrollHeight;
          }
        }, 0);
      } else {
        // new items appeared, vue-chat-scroll is handling this scenario
      }
    }
  },
};
</script>

<style scoped>
.icon-fill {
  fill: #828282;
}

.add-icon {
  width: 26px;
  height: 26px;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
}

.white-icon-fill {
  fill: white;
}

.fade-enter-active {
  transition: opacity 0.1s;
}

.fade-leave-active {
  transition: opacity 0.05s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
