<template>
  <div>
    <!--MODULE SPECIFIC FIELDS-->
    <div class="mt-1">
      <!--APP NAME-->
      <div class="field">
        <label class="label has-text-primary">{{ $t("admin_panel.mobile_app_app_name") }}</label>
        <b-button type="is-primary" class="admin-translation-key-button" @click="editAppName">
          {{ appNameLabel }}
        </b-button>
      </div>

      <!--DESCRIPTION-->
      <div class="field">
        <label class="label has-text-primary">{{ $t("admin_panel.mobile_app_description") }}</label>
        <b-button type="is-primary" class="admin-translation-key-button" @click="editDescription">
          {{ descriptionLabel }}
        </b-button>
      </div>

      <!--PREVIEW IMAGE VISIBLE-->
      <b-field>
        <b-switch v-model="$data[moduleFields.PREVIEW_IMAGE_VISIBLE]">
          {{ $t("admin_panel.mobile_app_preview_image_visible") }}
        </b-switch>
      </b-field>

      <!--PREVIEW IMAGE-->
      <div class="field" v-if="$data[moduleFields.PREVIEW_IMAGE_VISIBLE]">
        <label class="label has-text-primary">{{ $t("admin_panel.mobile_app_preview_image") }}</label>
        <b-button type="is-primary" class="admin-translation-key-button" @click="editPreviewImage">
          {{ previewImageLabel }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import LpConfigConstants from "@/shared/constants/lp-config-constants";

export default {
  name: "AdminModuleConfigMobileAppInfo",

  props: {
    value: {
      type: Object,
      required: false,
    },
  },

  data() {
    const moduleFields = LpConfigConstants.MOBILE_APP_INFO_FIELDS;
    return {
      [moduleFields.APP_NAME]: this.value[moduleFields.APP_NAME],
      [moduleFields.DESCRIPTION]: this.value[moduleFields.DESCRIPTION],
      [moduleFields.PREVIEW_IMAGE]: this.value[moduleFields.PREVIEW_IMAGE],
      [moduleFields.PREVIEW_IMAGE_VISIBLE]: this.value[moduleFields.PREVIEW_IMAGE_VISIBLE] ?? true,

      awaitingFieldForTranslationKey: null,
    };
  },

  created() {
    this.$root.$on("translation-key-selected", this.onTranslationKeySelected);
  },

  beforeDestroy() {
    this.$root.$off("translation-key-selected", this.onTranslationKeySelected);
  },

  computed: {
    moduleFields: () => LpConfigConstants.MOBILE_APP_INFO_FIELDS,

    appNameLabel() {
      return this.$data[this.moduleFields.APP_NAME] || this.$t("admin_panel.select_translation_key");
    },

    descriptionLabel() {
      return this.$data[this.moduleFields.DESCRIPTION] || this.$t("admin_panel.select_translation_key");
    },

    previewImageLabel() {
      return this.$data[this.moduleFields.PREVIEW_IMAGE] || this.$t("admin_panel.select_translation_key");
    },

    specificFieldsObject() {
      return Object.values(this.moduleFields).reduce((acc, fieldKey) => {
        return { ...acc, [fieldKey]: this.$data[fieldKey] };
      }, {});
    },
  },

  methods: {
    editAppName() {
      this.awaitingFieldForTranslationKey = this.moduleFields.APP_NAME;
      this.$root.$emit("select-translation-key");
    },

    editDescription() {
      this.awaitingFieldForTranslationKey = this.moduleFields.DESCRIPTION;
      this.$root.$emit("select-translation-key");
    },

    editPreviewImage() {
      this.awaitingFieldForTranslationKey = this.moduleFields.PREVIEW_IMAGE;
      this.$root.$emit("select-translation-key");
    },

    onTranslationKeySelected(translationKey) {
      if (this.awaitingFieldForTranslationKey) {
        this.$data[this.awaitingFieldForTranslationKey] = translationKey;
      }
      this.awaitingFieldForTranslationKey = null;
    },

    getModule() {
      return Object.values(this.moduleFields).reduce((acc, fieldKey) => {
        return { ...acc, [fieldKey]: this.$data[fieldKey] };
      }, {});
    },
  },

  watch: {
    specificFieldsObject: {
      immediate: false,
      handler(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
};
</script>

<style scoped></style>
