<template>
  <div>
    <!--MODULE SPECIFIC FIELDS-->
    <div class="mt-1">
      <!--PAGE SIZE-->
      <div class="field">
        <label class="label has-text-primary">{{ $t("admin_panel.exhibitors_page_size") }}</label>
        <div class="control">
          <input class="input is-simple" type="number" min="0" step="1"
                 :placeholder="$t('admin_panel.exhibitors_page_size')"
                 v-model.number="$data[moduleFields.PAGE_SIZE]"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LpConfigConstants from "@/shared/constants/lp-config-constants";

export default {
  name: "AdminModuleConfigExhibitors",

  props: {
    value: {
      type: Object,
      required: true,
    },
  },

  data() {
    const moduleFields = LpConfigConstants.EXHIBITORS_MODULE_FIELDS;
    return {
      [moduleFields.PAGE_SIZE]: this.value[moduleFields.PAGE_SIZE],
    };
  },

  computed: {
    moduleFields: () => LpConfigConstants.EXHIBITORS_MODULE_FIELDS,

    specificFieldsObject() {
      return Object.values(this.moduleFields).reduce((acc, fieldKey) => {
        return { ...acc, [fieldKey]: this.$data[fieldKey] };
      }, {});
    },
  },

  watch: {
    specificFieldsObject: {
      immediate: false,
      handler(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
};
</script>

<style scoped></style>
