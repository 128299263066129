<template>
  <div class="box">
    <div class="is-size-5 has-text-centered">{{ $t("admin_panel.features_configuration_title") }}</div>
    <div class="is-flex is-flex-direction-row is-align-items-center is-justify-content-center mb-2">
      <b-button type="is-primary" class="is-small mr-1" @click="save">{{ $t("admin_panel.save") }}</b-button>
      <b-button type="is-primary" class="is-small mr-1" @click="goBack" v-if="currentSubpanelIndex">
        {{ $t("admin_panel.return") }}
      </b-button>
    </div>

    <template v-if="!currentSubpanelIndex">
      <b-button type="is-primary" class="is-fullwidth mt-1" @click="openGeneralSettingsSubpanel">
        {{ $t("admin_panel.features_general_settings") }}
      </b-button>
      <b-button type="is-primary" class="is-fullwidth mt-1" @click="openAgendaRatingPopupSubpanel">
        {{ $t("admin_panel.features_agenda_rating_popup") }}
      </b-button>
      <b-button type="is-primary" class="is-fullwidth mt-1" @click="openCustomModuleSeparatorSubpanel">
        {{ $t("admin_panel.features_custom_module_separator") }}
      </b-button>
    </template>
    <template v-else-if="currentSubpanelIndex === SUBPANELS.AGENDA_POPUP_RATING">
      <!--EVENT ID-->
      <div class="field">
        <label class="label has-text-primary">{{ $t("admin_panel.features_agenda_rating_popup_event_id") }}</label>
        <div class="control">
          <input class="input is-simple" type="text" v-model="agendaRatingPopupEventId" :disabled="true" />
        </div>
      </div>

      <!--QUIZ COMPONENT ID-->
      <div class="field">
        <label class="label has-text-primary mb-1">{{ $t("admin_panel.features_agenda_rating_popup_quiz_component") }}</label>
        <dropdown-select :items="quizComponents" :value="quizComponentIndexSelected" @input="onNewQuizComponentIndexSelected">
          <template #dropdown="{ item }">
            <span>{{ item.label }}</span>
          </template>

          <template #placeholder>
            <span class="has-text-secondary">{{ $t("common.select_from_list") }}</span>
          </template>
        </dropdown-select>
      </div>
    </template>
    <template v-else-if="currentSubpanelIndex === SUBPANELS.CUSTOM_SEPARATOR">
      <!--CUSTOM SEPARATOR-->
      <div class="field mt-1">
        <label class="label has-text-primary">{{ $t("admin_panel.features_custom_module_separator_image_url") }}</label>
        <b-button type="is-primary" class="admin-translation-key-button" @click="editCustomSeparator">
          {{ customSeparatorLabel }}
        </b-button>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import LpConfigConstants from "@/shared/constants/lp-config-constants";
import DropdownSelect from "@/shared/components/DropdownSelect";

const SUBPANELS = {
  GENERAL_SETTINGS: "general_settings",
  AGENDA_POPUP_RATING: "agenda_popup_rating",
  CUSTOM_SEPARATOR: "custom_separator",
};

export default {
  name: "AdminFeaturesConfiguration",

  components: { DropdownSelect },

  data() {
    const featuresFields = LpConfigConstants.FEATURES_FIELDS;

    return {
      currentSubpanelIndex: null,
      agendaRatingPopupEventId: null,
      agendaRatingPopupQuizComponentId: null,
      quizComponentIndexSelected: null,
      [featuresFields.CUSTOM_SEPARATOR]: null,
      awaitingFieldForTranslationKey: null,
    };
  },

  created() {
    this.$root.$on("translation-key-selected", this.onTranslationKeySelected);
  },

  beforeDestroy() {
    this.$root.$off("translation-key-selected", this.onTranslationKeySelected);
  },

  computed: {
    ...mapState("adminPanel", ["features"]),
    ...mapState(["eventId"]),
    ...mapGetters("components", ["quizComponents"]),

    SUBPANELS: () => SUBPANELS,

    featuresFields: () => LpConfigConstants.FEATURES_FIELDS,

    featuresConfig() {
      const featuresFields = LpConfigConstants.FEATURES_FIELDS;
      return {
        [featuresFields.AGENDA_RATING_POPUP]: {
          [featuresFields.AGENDA_RATING_POPUP_FIELDS.EVENT_ID]: Number.parseInt(this.agendaRatingPopupEventId),
          [featuresFields.AGENDA_RATING_POPUP_FIELDS.QUIZ_COMPONENT_ID]: Number.parseInt(this.agendaRatingPopupQuizComponentId),
        },
        [featuresFields.CUSTOM_SEPARATOR]: this.$data[featuresFields.CUSTOM_SEPARATOR],
      };
    },

    customSeparatorLabel() {
      return this.$data[this.featuresFields.CUSTOM_SEPARATOR] || this.$t("admin_panel.select_translation_key");
    },
  },

  methods: {
    ...mapMutations("adminPanel", ["setFeaturesConfig"]),

    save() {
      this.setFeaturesConfig(this.featuresConfig);
      this.$root.$emit("close-side-panel");
      this.$emit("navigate-back");
    },

    openAgendaRatingPopupSubpanel() {
      this.currentSubpanelIndex = SUBPANELS.AGENDA_POPUP_RATING;
    },

    openCustomModuleSeparatorSubpanel() {
      this.currentSubpanelIndex = SUBPANELS.CUSTOM_SEPARATOR;
    },

    openGeneralSettingsSubpanel() {
      this.currentSubpanelIndex = SUBPANELS.GENERAL_SETTINGS;
    },

    goBack() {
      this.currentSubpanelIndex = null;
      this.$root.$emit("close-side-panel");
    },

    onNewQuizComponentIndexSelected(index) {
      this.quizComponentIndexSelected = index;
      this.agendaRatingPopupQuizComponentId = this.quizComponents[index].id;
    },

    editCustomSeparator() {
      this.awaitingFieldForTranslationKey = this.featuresFields.CUSTOM_SEPARATOR;
      this.$root.$emit("select-translation-key");
    },

    onTranslationKeySelected(translationKey) {
      if (this.awaitingFieldForTranslationKey) {
        this.$data[this.awaitingFieldForTranslationKey] = translationKey;
      }
      this.awaitingFieldForTranslationKey = null;
    },
  },

  watch: {
    features: {
      immediate: true,
      handler: function (newValue) {
        const featureFields = LpConfigConstants.FEATURES_FIELDS;
        this.agendaRatingPopupQuizComponentId =
          newValue[featureFields.AGENDA_RATING_POPUP] &&
          newValue[featureFields.AGENDA_RATING_POPUP][featureFields.AGENDA_RATING_POPUP_FIELDS.QUIZ_COMPONENT_ID];
        this.quizComponentIndexSelected = this.quizComponents.findIndex(
          component => component.id === this.agendaRatingPopupQuizComponentId
        );
        this.$data[featureFields.CUSTOM_SEPARATOR] = newValue[featureFields.CUSTOM_SEPARATOR];
      },
    },

    eventId: {
      immediate: true,
      handler: function (newValue) {
        this.agendaRatingPopupEventId = newValue;
      },
    },
  },
};
</script>

<style scoped></style>
