<template>
  <div>
    <!--MODULE SPECIFIC FIELDS-->
    <div class="mt-1">
      <!--Twitter profile url-->
      <div class="field">
        <label class="label has-text-primary">{{ $t("admin_panel.twitter_profile_url") }}</label>
        <div class="control">
          <input class="input is-simple" type="text" :placeholder="$t('admin_panel.twitter_profile_url')"
                 v-model="$data[moduleFields.TWITTER_URL]"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LpConfigConstants from "@/shared/constants/lp-config-constants";

export default {
  name: "AdminModuleConfigTwitter",

  props: {
    value: {
      type: Object,
      required: true,
    },
  },

  data() {
    const moduleFields = LpConfigConstants.TWITTER_MODULE_FIELDS;
    return {
      [moduleFields.TWITTER_URL]: this.value[moduleFields.TWITTER_URL],
    };
  },

  computed: {
    moduleFields: () => LpConfigConstants.TWITTER_MODULE_FIELDS,

    specificFieldsObject() {
      return Object.values(this.moduleFields).reduce((acc, fieldKey) => {
        return { ...acc, [fieldKey]: this.$data[fieldKey] };
      }, {});
    },
  },

  watch: {
    specificFieldsObject: {
      immediate: false,
      handler(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
};
</script>

<style scoped></style>
