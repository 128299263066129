<template>
  <div v-if="agendaSessions.length">
    <agenda-session
      v-for="session in agendaSessions"
      :key="session.id"
      class="mb-1"
      :session="session"
      @click.native="$emit('select-agenda-session', session.id)"
      :is-small="true"
      :selectable="true"
    ></agenda-session>
  </div>
  <div class="placeholder-container" v-else>
    {{ $t("session_questions.sessions_empty_placeholder") }}
  </div>
</template>

<script>
import {mapMutations} from "vuex";
import AgendaSession from "@/web/components/agenda/AgendaSession";

export default {
  name: "AgendaBubbleSessionList",

  components: {AgendaSession},

  props: {
    agendaSessions: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      timeUpdatesRunning: false,
      ongoingTimeout: null,
    };
  },

  methods: {
    ...mapMutations("agendaSessionQuestions", ["updateCurrentTime"]),

    startTimeUpdates() {
      this.timeUpdatesRunning = true;
      this.nextUpdate();
    },

    stopTimeUpdates() {
      this.timeUpdatesRunning = false;
      clearTimeout(this.ongoingTimeout);
    },

    nextUpdate() {
      if (this.timeUpdatesRunning) {
        this.updateCurrentTime();
        this.ongoingTimeout = setTimeout(() => this.nextUpdate(), 60 * 1000);
      }
    },
  },

  mounted() {
    this.startTimeUpdates();
  },

  beforeDestroy() {
    this.stopTimeUpdates();
  },
};
</script>

<style scoped>
.placeholder-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-size: 16px;
  background-color: var(--side-menu-background-color);
  color: var(--text-color-secondary);
}
</style>
